import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'retos', pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule) },
  { path: 'retos', loadChildren: () => import('./retos/retos.module').then(m => m.RetosPageModule), canLoad: [AuthGuard] },
  {
    path: 'retos-list',
    loadChildren: () => import('./retos-list/retos-list.module').then(m => m.RetosListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'areas',
    loadChildren: () => import('./areas/areas.module').then(m => m.AreasPageModule)
  },
  {
    path: 'stats',
    loadChildren: () => import('./stats/stats.module').then( m => m.StatsPageModule)
  },

  // {
  //   path: 'retos-list',
  //   loadChildren: () => import('./retos-list/retos-list.module').then( m => m.RetosListPageModule)
  // },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
