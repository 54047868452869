import { EvaluInterface } from "./evalu-interface.model";

export class QAResta implements EvaluInterface {
  private id: string;
  private q: string;
  private a: string;
  private rt: number;

  private v1: number;
  private v2: number;

  constructor(_id: string, _v1: number, _v2: number, _rt: number) {
    this.id = _id;
    this.v1 = _v1;
    this.v2 = _v2;
    this.rt = _rt;
  }

  getId(): string {
    return this.id;
  }

  getQNew(): string {
    var bInt = this.getRandomInt(9);
    var v1Temp = 10 + 10 * bInt + this.v1;
    var v2Temp = 10 * this.getRandomInt(1 + bInt) + this.v2;
    this.q = v1Temp + " - " + v2Temp;
    this.a = v1Temp - v2Temp + "";
    return this.q;
  }

  getQ(): string {
    return this.q;
  }
  
  getQShort(): string {
    return this.q;
  }

  getA(): string {
    return this.a;
  }

  gradeAnswer(answer: string): number {
    return this.a == answer ? 1.0 : 0.0;
  }

  getTimeToRespondMs() {
    return this.rt;
  }

  getEvalDisplay() {
    return "matheDisplay";
  }

  private getRandomInt(max: number) {
    return Math.floor(Math.random() * Math.floor(max));
  }
}
