/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateArea: OnCreateAreaSubscription;
  onCreateEvaluation: OnCreateEvaluationSubscription;
  onCreateProgress: OnCreateProgressSubscription;
  onCreateResponse: OnCreateResponseSubscription;
  onCreateReto: OnCreateRetoSubscription;
  onCreateRole: OnCreateRoleSubscription;
  onCreateUsuario: OnCreateUsuarioSubscription;
  onDeleteArea: OnDeleteAreaSubscription;
  onDeleteEvaluation: OnDeleteEvaluationSubscription;
  onDeleteProgress: OnDeleteProgressSubscription;
  onDeleteResponse: OnDeleteResponseSubscription;
  onDeleteReto: OnDeleteRetoSubscription;
  onDeleteRole: OnDeleteRoleSubscription;
  onDeleteUsuario: OnDeleteUsuarioSubscription;
  onUpdateArea: OnUpdateAreaSubscription;
  onUpdateEvaluation: OnUpdateEvaluationSubscription;
  onUpdateProgress: OnUpdateProgressSubscription;
  onUpdateResponse: OnUpdateResponseSubscription;
  onUpdateReto: OnUpdateRetoSubscription;
  onUpdateUsuario: OnUpdateUsuarioSubscription;
  oncreateEvaluationHasPrerequisit: OncreateEvaluationHasPrerequisitSubscription;
  oncreateRetoHasEvaluation: OncreateRetoHasEvaluationSubscription;
  oncreateUsuarioHasReto: OncreateUsuarioHasRetoSubscription;
  ondeleteEvaluationHasPrerequisit: OndeleteEvaluationHasPrerequisitSubscription;
  ondeleteRetoHasEvaluation: OndeleteRetoHasEvaluationSubscription;
  ondeleteUsuarioHasReto: OndeleteUsuarioHasRetoSubscription;
  onupdateEvaluationHasPrerequisit: OnupdateEvaluationHasPrerequisitSubscription;
  onupdateUsuarioHasReto: OnupdateUsuarioHasRetoSubscription;
};

export type CreateAreaInput = {
  areaDescription?: string | null;
  areaImage?: string | null;
  areaName: string;
};

export type Area = {
  __typename: "Area";
  areaDescription?: string | null;
  areaId: string;
  areaImage?: string | null;
  areaName: string;
  createdAt: string;
  modifiedAt: string;
};

export type CreateEvaluationInput = {
  evaluationAnswerClassName: string;
  evaluationAnswerParameters: string;
  evaluationMsResponseTimeGoal: number;
  evaluationQuestionClassName: string;
  evaluationQuestionParameters: string;
};

export type Evaluation = {
  __typename: "Evaluation";
  createdAt: string;
  evaluationAnswerClassName: string;
  evaluationAnswerParameters: string;
  evaluationId: string;
  evaluationMsResponseTimeGoal: number;
  evaluationQuestionClassName: string;
  evaluationQuestionParameters: string;
  modifiedAt: string;
};

export type CreateEvaluationHasPrerequisitInput = {
  evaluationId: string;
  evaluationIdPre: string;
};

export type EvaluationPrerequisit = {
  __typename: "EvaluationPrerequisit";
  evaluationId: string;
  evaluationIdPre: string;
};

export type CreateEvaluationWithRetoInput = {
  evaluationAnswerClassName: string;
  evaluationAnswerParameters: string;
  evaluationMsResponseTimeGoal: number;
  evaluationQuestionClassName: string;
  evaluationQuestionParameters: string;
  retoId: string;
};

export type CreateProgressInput = {
  didPass?: boolean | null;
  evaluationId: string;
  msToRespondFirstOfTheDay?: number | null;
  scoreFirstOfTheDay?: number | null;
  usuarioId: string;
};

export type Progress = {
  __typename: "Progress";
  createdAt: string;
  dateLastUpdateLOC?: string | null;
  dateNextEvaluationLOC?: string | null;
  didPass?: boolean | null;
  evaluationId: string;
  maxTimeNextEvaluation?: number | null;
  modifiedAt: string;
  msToRespondFirstOfTheDay?: number | null;
  r?: number | null;
  scoreFirstOfTheDay?: number | null;
  usuarioId: string;
};

export type CreateResponseInput = {
  agent: string;
  answerAsTxt: string;
  createdAt: string;
  didPass?: boolean | null;
  evaluationId: string;
  keyStrokes: string;
  msToRespond: number;
  numStops: number;
  questionAsTxt: string;
  responseAsTxt: string;
  score: number;
  usuarioId: string;
};

export type Response = {
  __typename: "Response";
  agent: string;
  answerAsTxt: string;
  createdAt: string;
  didPass?: boolean | null;
  evaluationId: string;
  keyStrokes: string;
  msToRespond: number;
  numStops: number;
  questionAsTxt: string;
  responseAsTxt: string;
  score: number;
  usuarioId: string;
};

export type CreateRetoInput = {
  areaId: string;
  retoDescription?: string | null;
  retoName: string;
};

export type Reto = {
  __typename: "Reto";
  areaId: string;
  createdAt: string;
  modifiedAt: string;
  retoDescription?: string | null;
  retoId: string;
  retoName: string;
  retoNumEvaluations: number;
};

export type CreateRetoHasEvaluationInput = {
  evaluationId: string;
  retoId: string;
};

export type RetoEvaluation = {
  __typename: "RetoEvaluation";
  evaluationId: string;
  retoId: string;
};

export type CreateRoleInput = {
  roleId: string;
  roleName: string;
};

export type Role = {
  __typename: "Role";
  createdAt: string;
  modifiedAt: string;
  roleName: string;
};

export type CreateUsuarioInput = {
  maxNew?: number | null;
  maxTotal?: number | null;
  registered: boolean;
  timeZone: string;
  username: string;
  usuarioId: string;
};

export type Usuario = {
  __typename: "Usuario";
  createdAt: string;
  maxNew?: number | null;
  maxTotal?: number | null;
  modifiedAt: string;
  registered: boolean;
  timeZone: string;
  username: string;
  usuarioId: string;
};

export type CreateUsuarioHasRetoInput = {
  progress: number;
  retoId: string;
  usuarioId: string;
};

export type UsuarioReto = {
  __typename: "UsuarioReto";
  totEval?: number | null;
  numEval?: number | null;
  progress?: number | null;
  retoId: string;
  usuarioId: string;
};

export type DeleteAreaInput = {
  areaId: string;
};

export type DeleteEvaluationInput = {
  evaluationId: string;
};

export type DeleteEvaluationHasPrerequisitInput = {
  evaluationId: string;
  evaluationIdPre: string;
};

export type DeleteProgressInput = {
  evaluationId: string;
  usuarioId: string;
};

export type DeleteResponseInput = {
  evaluationId: string;
  usuarioId: string;
};

export type DeleteRetoInput = {
  retoId: string;
};

export type DeleteRetoHasEvaluationInput = {
  evaluationId: string;
  retoId: string;
};

export type DeleteRoleInput = {
  roleName: string;
};

export type DeleteUsuarioInput = {
  usuarioId: string;
};

export type DeleteUsuarioHasRetoInput = {
  retoId: string;
  usuarioId: string;
};

export type UpdateAreaInput = {
  areaDescription?: string | null;
  areaId: string;
  areaImage?: string | null;
  areaName?: string | null;
  createdAt?: string | null;
  modifiedAt?: string | null;
};

export type UpdateEvaluationInput = {
  createdAt?: string | null;
  evaluationAnswerClassName?: string | null;
  evaluationAnswerParameters?: string | null;
  evaluationId: string;
  evaluationMsResponseTimeGoal?: number | null;
  evaluationQuestionClassName?: string | null;
  evaluationQuestionParameters?: string | null;
  modifiedAt?: string | null;
};

export type UpdateEvaluationHasPrerequisitInput = {
  evaluationId: string;
  evaluationIdPre: string;
};

export type UpdateProgressInput = {
  dateLastUpdateLOC: string;
  dateNextEvaluationLOC: string;
  didPass: boolean;
  evaluationId: string;
  maxTimeNextEvaluation: number;
  msToRespondFirstOfTheDay: number;
  r: number;
  scoreFirstOfTheDay: number;
  usuarioId: string;
};

export type UpdateResponseInput = {
  agent?: string | null;
  answerAsTxt?: string | null;
  createdAt?: string | null;
  didPass?: boolean | null;
  evaluationId: string;
  keyStrokes?: string | null;
  msToRespond?: number | null;
  numStops?: number | null;
  questionAsTxt?: string | null;
  responseAsTxt?: string | null;
  score?: number | null;
  usuarioId: string;
};

export type UpdateRetoInput = {
  areaId?: string | null;
  createdAt?: string | null;
  modifiedAt?: string | null;
  retoDescription?: string | null;
  retoId: string;
  retoName?: string | null;
};

export type UpdateUsuarioInput = {
  createdAt?: string | null;
  maxNew?: number | null;
  maxTotal?: number | null;
  modifiedAt?: string | null;
  registered?: boolean | null;
  timeZone?: string | null;
  username?: string | null;
  usuarioId: string;
};

export type UpdateUsuarioHasRetoInput = {
  progress: number;
  retoId: string;
  usuarioId: string;
};

export type TableAreaFilterInput = {
  areaDescription?: TableStringFilterInput | null;
  areaId?: TableIDFilterInput | null;
  areaImage?: TableStringFilterInput | null;
  areaName?: TableStringFilterInput | null;
  createdAt?: TableStringFilterInput | null;
  modifiedAt?: TableStringFilterInput | null;
};

export type TableStringFilterInput = {
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  contains?: string | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
  ne?: string | null;
  notContains?: string | null;
};

export type TableIDFilterInput = {
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  contains?: string | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
  ne?: string | null;
  notContains?: string | null;
};

export type AreaConnection = {
  __typename: "AreaConnection";
  items?: Array<Area | null> | null;
  nextToken?: string | null;
};

export type TableEvaluationPrerequisitFilterInput = {
  evaluationId?: TableIDFilterInput | null;
  evaluationIdPre?: TableIDFilterInput | null;
};

export type EvaluationPrerequisitConnection = {
  __typename: "EvaluationPrerequisitConnection";
  items?: Array<EvaluationPrerequisit | null> | null;
  nextToken?: string | null;
};

export type TableEvaluationFilterInput = {
  createdAt?: TableStringFilterInput | null;
  evaluationAnswerClassName?: TableStringFilterInput | null;
  evaluationId?: TableIDFilterInput | null;
  evaluationMsResponseTimeGoal?: TableIntFilterInput | null;
  evaluationQuestionClassName?: TableStringFilterInput | null;
  modifiedAt?: TableStringFilterInput | null;
};

export type TableIntFilterInput = {
  between?: Array<number | null> | null;
  contains?: number | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
  notContains?: number | null;
};

export type EvaluationConnection = {
  __typename: "EvaluationConnection";
  items?: Array<Evaluation | null> | null;
  nextToken?: string | null;
};

export type TableProgressFilterInput = {
  createdAt?: TableStringFilterInput | null;
  dateNextEvaluation?: TableStringFilterInput | null;
  evaluationId?: TableIDFilterInput | null;
  maxTimeNextEvaluation?: TableIntFilterInput | null;
  modifiedAt?: TableStringFilterInput | null;
  msToRespondFirstOfTheDay?: TableIntFilterInput | null;
  r?: TableFloatFilterInput | null;
  scoreFirstOfTheDay?: TableFloatFilterInput | null;
  usuarioId?: TableIDFilterInput | null;
};

export type TableFloatFilterInput = {
  between?: Array<number | null> | null;
  contains?: number | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
  notContains?: number | null;
};

export type ProgressConnection = {
  __typename: "ProgressConnection";
  items?: Array<Progress | null> | null;
  nextToken?: string | null;
};

export type TableResponseFilterInput = {
  agent?: TableStringFilterInput | null;
  answerAsTxt?: TableStringFilterInput | null;
  createdAt?: TableStringFilterInput | null;
  didPass?: TableBooleanFilterInput | null;
  evaluationId?: TableIDFilterInput | null;
  keyStrokes?: TableStringFilterInput | null;
  msToRespond?: TableIntFilterInput | null;
  numStops?: TableIntFilterInput | null;
  questionAsTxt?: TableStringFilterInput | null;
  responseAsTxt?: TableStringFilterInput | null;
  score?: TableFloatFilterInput | null;
  usuarioId?: TableIDFilterInput | null;
};

export type TableBooleanFilterInput = {
  eq?: boolean | null;
  ne?: boolean | null;
};

export type ResponseConnection = {
  __typename: "ResponseConnection";
  items?: Array<Response | null> | null;
  nextToken?: string | null;
};

export type TableRetoEvaluationFilterInput = {
  evaluationId?: TableIDFilterInput | null;
  retoId?: TableIDFilterInput | null;
};

export type RetoEvaluationConnection = {
  __typename: "RetoEvaluationConnection";
  items?: Array<RetoEvaluation | null> | null;
  nextToken?: string | null;
};

export type TableRetoFilterInput = {
  areaId?: TableIDFilterInput | null;
  createdAt?: TableStringFilterInput | null;
  modifiedAt?: TableStringFilterInput | null;
  retoDescription?: TableStringFilterInput | null;
  retoId?: TableIDFilterInput | null;
  retoName?: TableStringFilterInput | null;
};

export type RetoConnection = {
  __typename: "RetoConnection";
  items?: Array<Reto | null> | null;
  nextToken?: string | null;
};

export type TableRoleFilterInput = {
  createdAt?: TableStringFilterInput | null;
  modifiedAt?: TableStringFilterInput | null;
  roleName?: TableStringFilterInput | null;
};

export type RoleConnection = {
  __typename: "RoleConnection";
  items?: Array<Role | null> | null;
  nextToken?: string | null;
};

export type TableUsuarioRetoFilterInput = {
  retoId?: TableIDFilterInput | null;
  usuarioId?: TableIDFilterInput | null;
};

export type UsuarioRetoConnection = {
  __typename: "UsuarioRetoConnection";
  items?: Array<UsuarioReto | null> | null;
  nextToken?: string | null;
};

export type TableUsuarioFilterInput = {
  createdAt?: TableStringFilterInput | null;
  maxNew?: TableIntFilterInput | null;
  maxTotal?: TableIntFilterInput | null;
  modifiedAt?: TableStringFilterInput | null;
  registered?: TableBooleanFilterInput | null;
  timeZone?: TableStringFilterInput | null;
  username?: TableStringFilterInput | null;
  usuarioId?: TableIDFilterInput | null;
};

export type UsuarioConnection = {
  __typename: "UsuarioConnection";
  items?: Array<Usuario | null> | null;
  nextToken?: string | null;
};

export type ProgressAlgorithInput = {
  current_dateLastUpdateLOC?: string | null;
  current_dateNextEvaluationLOC?: string | null;
  current_didPass?: boolean | null;
  current_maxTimeNextEvaluation?: number | null;
  current_msToRespondFirstOfTheDay?: number | null;
  current_r?: number | null;
  current_scoreFirstOfTheDay?: number | null;
  evalu_timeGoal: number;
  response_didPass: boolean;
  response_msToResponde: number;
  response_score: number;
};

export type ProgressAlgorithOutput = {
  __typename: "ProgressAlgorithOutput";
  dateLastUpdateLOC: string;
  dateNextEvaluationLOC: string;
  didPass: boolean;
  maxTimeNextEvaluation: number;
  msToRespondFirstOfTheDay: number;
  r: number;
  scoreFirstOfTheDay: number;
};

export type Stats = {
  __typename: "Stats";
  cntDays?: number | null;
  cntRetosCompleted?: number | null;
  cntRetosCompletedToday?: number | null;
  cntEvalReady?: number | null;
  rachaDays?: number | null;
};

export type CreateAreaMutation = {
  __typename: "Area";
  areaDescription?: string | null;
  areaId: string;
  areaImage?: string | null;
  areaName: string;
  createdAt: string;
  modifiedAt: string;
};

export type CreateEvaluationMutation = {
  __typename: "Evaluation";
  createdAt: string;
  evaluationAnswerClassName: string;
  evaluationAnswerParameters: string;
  evaluationId: string;
  evaluationMsResponseTimeGoal: number;
  evaluationQuestionClassName: string;
  evaluationQuestionParameters: string;
  modifiedAt: string;
};

export type CreateEvaluationHasPrerequisitMutation = {
  __typename: "EvaluationPrerequisit";
  evaluationId: string;
  evaluationIdPre: string;
};

export type CreateEvaluationWithRetoMutation = {
  __typename: "Evaluation";
  createdAt: string;
  evaluationAnswerClassName: string;
  evaluationAnswerParameters: string;
  evaluationId: string;
  evaluationMsResponseTimeGoal: number;
  evaluationQuestionClassName: string;
  evaluationQuestionParameters: string;
  modifiedAt: string;
};

export type CreateProgressMutation = {
  __typename: "Progress";
  createdAt: string;
  dateLastUpdateLOC?: string | null;
  dateNextEvaluationLOC?: string | null;
  didPass?: boolean | null;
  evaluationId: string;
  maxTimeNextEvaluation?: number | null;
  modifiedAt: string;
  msToRespondFirstOfTheDay?: number | null;
  r?: number | null;
  scoreFirstOfTheDay?: number | null;
  usuarioId: string;
};

export type CreateResponseMutation = {
  __typename: "Response";
  agent: string;
  answerAsTxt: string;
  createdAt: string;
  didPass?: boolean | null;
  evaluationId: string;
  keyStrokes: string;
  msToRespond: number;
  numStops: number;
  questionAsTxt: string;
  responseAsTxt: string;
  score: number;
  usuarioId: string;
};

export type CreateRetoMutation = {
  __typename: "Reto";
  areaId: string;
  createdAt: string;
  modifiedAt: string;
  retoDescription?: string | null;
  retoId: string;
  retoName: string;
  retoNumEvaluations: number;
};

export type CreateRetoHasEvaluationMutation = {
  __typename: "RetoEvaluation";
  evaluationId: string;
  retoId: string;
};

export type CreateRoleMutation = {
  __typename: "Role";
  createdAt: string;
  modifiedAt: string;
  roleName: string;
};

export type CreateUsuarioMutation = {
  __typename: "Usuario";
  createdAt: string;
  maxNew?: number | null;
  maxTotal?: number | null;
  modifiedAt: string;
  registered: boolean;
  timeZone: string;
  username: string;
  usuarioId: string;
};

export type CreateUsuarioHasRetoMutation = {
  __typename: "UsuarioReto";
  totEval?: number | null;
  numEval?: number | null;
  progress?: number | null;
  retoId: string;
  usuarioId: string;
};

export type DeleteAreaMutation = {
  __typename: "Area";
  areaDescription?: string | null;
  areaId: string;
  areaImage?: string | null;
  areaName: string;
  createdAt: string;
  modifiedAt: string;
};

export type DeleteEvaluationMutation = {
  __typename: "Evaluation";
  createdAt: string;
  evaluationAnswerClassName: string;
  evaluationAnswerParameters: string;
  evaluationId: string;
  evaluationMsResponseTimeGoal: number;
  evaluationQuestionClassName: string;
  evaluationQuestionParameters: string;
  modifiedAt: string;
};

export type DeleteEvaluationHasPrerequisitMutation = {
  __typename: "EvaluationPrerequisit";
  evaluationId: string;
  evaluationIdPre: string;
};

export type DeleteProgressMutation = {
  __typename: "Progress";
  createdAt: string;
  dateLastUpdateLOC?: string | null;
  dateNextEvaluationLOC?: string | null;
  didPass?: boolean | null;
  evaluationId: string;
  maxTimeNextEvaluation?: number | null;
  modifiedAt: string;
  msToRespondFirstOfTheDay?: number | null;
  r?: number | null;
  scoreFirstOfTheDay?: number | null;
  usuarioId: string;
};

export type DeleteResponseMutation = {
  __typename: "Response";
  agent: string;
  answerAsTxt: string;
  createdAt: string;
  didPass?: boolean | null;
  evaluationId: string;
  keyStrokes: string;
  msToRespond: number;
  numStops: number;
  questionAsTxt: string;
  responseAsTxt: string;
  score: number;
  usuarioId: string;
};

export type DeleteRetoMutation = {
  __typename: "Reto";
  areaId: string;
  createdAt: string;
  modifiedAt: string;
  retoDescription?: string | null;
  retoId: string;
  retoName: string;
  retoNumEvaluations: number;
};

export type DeleteRetoHasEvaluationMutation = {
  __typename: "RetoEvaluation";
  evaluationId: string;
  retoId: string;
};

export type DeleteRoleMutation = {
  __typename: "Role";
  createdAt: string;
  modifiedAt: string;
  roleName: string;
};

export type DeleteUsuarioMutation = {
  __typename: "Usuario";
  createdAt: string;
  maxNew?: number | null;
  maxTotal?: number | null;
  modifiedAt: string;
  registered: boolean;
  timeZone: string;
  username: string;
  usuarioId: string;
};

export type DeleteUsuarioHasRetoMutation = {
  __typename: "UsuarioReto";
  totEval?: number | null;
  numEval?: number | null;
  progress?: number | null;
  retoId: string;
  usuarioId: string;
};

export type SendResponseMutation = {
  __typename: "Progress";
  createdAt: string;
  dateLastUpdateLOC?: string | null;
  dateNextEvaluationLOC?: string | null;
  didPass?: boolean | null;
  evaluationId: string;
  maxTimeNextEvaluation?: number | null;
  modifiedAt: string;
  msToRespondFirstOfTheDay?: number | null;
  r?: number | null;
  scoreFirstOfTheDay?: number | null;
  usuarioId: string;
};

export type UpdateAreaMutation = {
  __typename: "Area";
  areaDescription?: string | null;
  areaId: string;
  areaImage?: string | null;
  areaName: string;
  createdAt: string;
  modifiedAt: string;
};

export type UpdateEvaluationMutation = {
  __typename: "Evaluation";
  createdAt: string;
  evaluationAnswerClassName: string;
  evaluationAnswerParameters: string;
  evaluationId: string;
  evaluationMsResponseTimeGoal: number;
  evaluationQuestionClassName: string;
  evaluationQuestionParameters: string;
  modifiedAt: string;
};

export type UpdateEvaluationHasPrerequisitMutation = {
  __typename: "EvaluationPrerequisit";
  evaluationId: string;
  evaluationIdPre: string;
};

export type UpdateProgressMutation = {
  __typename: "Progress";
  createdAt: string;
  dateLastUpdateLOC?: string | null;
  dateNextEvaluationLOC?: string | null;
  didPass?: boolean | null;
  evaluationId: string;
  maxTimeNextEvaluation?: number | null;
  modifiedAt: string;
  msToRespondFirstOfTheDay?: number | null;
  r?: number | null;
  scoreFirstOfTheDay?: number | null;
  usuarioId: string;
};

export type UpdateResponseMutation = {
  __typename: "Response";
  agent: string;
  answerAsTxt: string;
  createdAt: string;
  didPass?: boolean | null;
  evaluationId: string;
  keyStrokes: string;
  msToRespond: number;
  numStops: number;
  questionAsTxt: string;
  responseAsTxt: string;
  score: number;
  usuarioId: string;
};

export type UpdateRetoMutation = {
  __typename: "Reto";
  areaId: string;
  createdAt: string;
  modifiedAt: string;
  retoDescription?: string | null;
  retoId: string;
  retoName: string;
  retoNumEvaluations: number;
};

export type UpdateUsuarioMutation = {
  __typename: "Usuario";
  createdAt: string;
  maxNew?: number | null;
  maxTotal?: number | null;
  modifiedAt: string;
  registered: boolean;
  timeZone: string;
  username: string;
  usuarioId: string;
};

export type UpdateUsuarioHasRetoMutation = {
  __typename: "UsuarioReto";
  totEval?: number | null;
  numEval?: number | null;
  progress?: number | null;
  retoId: string;
  usuarioId: string;
};

export type GetAreaQuery = {
  __typename: "Area";
  areaDescription?: string | null;
  areaId: string;
  areaImage?: string | null;
  areaName: string;
  createdAt: string;
  modifiedAt: string;
};

export type GetEvaluationQuery = {
  __typename: "Evaluation";
  createdAt: string;
  evaluationAnswerClassName: string;
  evaluationAnswerParameters: string;
  evaluationId: string;
  evaluationMsResponseTimeGoal: number;
  evaluationQuestionClassName: string;
  evaluationQuestionParameters: string;
  modifiedAt: string;
};

export type GetEvaluationHasPrerequisitQuery = {
  __typename: "EvaluationPrerequisit";
  evaluationId: string;
  evaluationIdPre: string;
};

export type GetProgressQuery = {
  __typename: "Progress";
  createdAt: string;
  dateLastUpdateLOC?: string | null;
  dateNextEvaluationLOC?: string | null;
  didPass?: boolean | null;
  evaluationId: string;
  maxTimeNextEvaluation?: number | null;
  modifiedAt: string;
  msToRespondFirstOfTheDay?: number | null;
  r?: number | null;
  scoreFirstOfTheDay?: number | null;
  usuarioId: string;
};

export type GetResponseQuery = {
  __typename: "Response";
  agent: string;
  answerAsTxt: string;
  createdAt: string;
  didPass?: boolean | null;
  evaluationId: string;
  keyStrokes: string;
  msToRespond: number;
  numStops: number;
  questionAsTxt: string;
  responseAsTxt: string;
  score: number;
  usuarioId: string;
};

export type GetRetoQuery = {
  __typename: "Reto";
  areaId: string;
  createdAt: string;
  modifiedAt: string;
  retoDescription?: string | null;
  retoId: string;
  retoName: string;
  retoNumEvaluations: number;
};

export type GetRetoHasEvaluationQuery = {
  __typename: "RetoEvaluation";
  evaluationId: string;
  retoId: string;
};

export type GetRoleQuery = {
  __typename: "Role";
  createdAt: string;
  modifiedAt: string;
  roleName: string;
};

export type GetUsuarioQuery = {
  __typename: "Usuario";
  createdAt: string;
  maxNew?: number | null;
  maxTotal?: number | null;
  modifiedAt: string;
  registered: boolean;
  timeZone: string;
  username: string;
  usuarioId: string;
};

export type GetUsuarioHasRetoQuery = {
  __typename: "UsuarioReto";
  totEval?: number | null;
  numEval?: number | null;
  progress?: number | null;
  retoId: string;
  usuarioId: string;
};

export type ListAreasQuery = {
  __typename: "AreaConnection";
  items?: Array<{
    __typename: "Area";
    areaDescription?: string | null;
    areaId: string;
    areaImage?: string | null;
    areaName: string;
    createdAt: string;
    modifiedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListEvaluationHasPrerequisitsQuery = {
  __typename: "EvaluationPrerequisitConnection";
  items?: Array<{
    __typename: "EvaluationPrerequisit";
    evaluationId: string;
    evaluationIdPre: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListEvaluationsQuery = {
  __typename: "EvaluationConnection";
  items?: Array<{
    __typename: "Evaluation";
    createdAt: string;
    evaluationAnswerClassName: string;
    evaluationAnswerParameters: string;
    evaluationId: string;
    evaluationMsResponseTimeGoal: number;
    evaluationQuestionClassName: string;
    evaluationQuestionParameters: string;
    modifiedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListEvaluationsByUsuarioAndRetoQuery = {
  __typename: "EvaluationConnection";
  items?: Array<{
    __typename: "Evaluation";
    createdAt: string;
    evaluationAnswerClassName: string;
    evaluationAnswerParameters: string;
    evaluationId: string;
    evaluationMsResponseTimeGoal: number;
    evaluationQuestionClassName: string;
    evaluationQuestionParameters: string;
    modifiedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListEvaluationsByUsuarioAndRetoTestQuery = {
  __typename: "EvaluationConnection";
  items?: Array<{
    __typename: "Evaluation";
    createdAt: string;
    evaluationAnswerClassName: string;
    evaluationAnswerParameters: string;
    evaluationId: string;
    evaluationMsResponseTimeGoal: number;
    evaluationQuestionClassName: string;
    evaluationQuestionParameters: string;
    modifiedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListEvaluationsForRetoQuery = {
  __typename: "EvaluationConnection";
  items?: Array<{
    __typename: "Evaluation";
    createdAt: string;
    evaluationAnswerClassName: string;
    evaluationAnswerParameters: string;
    evaluationId: string;
    evaluationMsResponseTimeGoal: number;
    evaluationQuestionClassName: string;
    evaluationQuestionParameters: string;
    modifiedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListEvaluationsForRetoByUsuarioQuery = {
  __typename: "EvaluationConnection";
  items?: Array<{
    __typename: "Evaluation";
    createdAt: string;
    evaluationAnswerClassName: string;
    evaluationAnswerParameters: string;
    evaluationId: string;
    evaluationMsResponseTimeGoal: number;
    evaluationQuestionClassName: string;
    evaluationQuestionParameters: string;
    modifiedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListProgressesQuery = {
  __typename: "ProgressConnection";
  items?: Array<{
    __typename: "Progress";
    createdAt: string;
    dateLastUpdateLOC?: string | null;
    dateNextEvaluationLOC?: string | null;
    didPass?: boolean | null;
    evaluationId: string;
    maxTimeNextEvaluation?: number | null;
    modifiedAt: string;
    msToRespondFirstOfTheDay?: number | null;
    r?: number | null;
    scoreFirstOfTheDay?: number | null;
    usuarioId: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListProgressesForUsuarioByRetoQuery = {
  __typename: "ProgressConnection";
  items?: Array<{
    __typename: "Progress";
    createdAt: string;
    dateLastUpdateLOC?: string | null;
    dateNextEvaluationLOC?: string | null;
    didPass?: boolean | null;
    evaluationId: string;
    maxTimeNextEvaluation?: number | null;
    modifiedAt: string;
    msToRespondFirstOfTheDay?: number | null;
    r?: number | null;
    scoreFirstOfTheDay?: number | null;
    usuarioId: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListResponsesQuery = {
  __typename: "ResponseConnection";
  items?: Array<{
    __typename: "Response";
    agent: string;
    answerAsTxt: string;
    createdAt: string;
    didPass?: boolean | null;
    evaluationId: string;
    keyStrokes: string;
    msToRespond: number;
    numStops: number;
    questionAsTxt: string;
    responseAsTxt: string;
    score: number;
    usuarioId: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListRetoHasEvaluationsQuery = {
  __typename: "RetoEvaluationConnection";
  items?: Array<{
    __typename: "RetoEvaluation";
    evaluationId: string;
    retoId: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListRetoHasEvaluationsByRetoQuery = {
  __typename: "RetoEvaluationConnection";
  items?: Array<{
    __typename: "RetoEvaluation";
    evaluationId: string;
    retoId: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListRetosQuery = {
  __typename: "RetoConnection";
  items?: Array<{
    __typename: "Reto";
    areaId: string;
    createdAt: string;
    modifiedAt: string;
    retoDescription?: string | null;
    retoId: string;
    retoName: string;
    retoNumEvaluations: number;
  } | null> | null;
  nextToken?: string | null;
};

export type ListRolesQuery = {
  __typename: "RoleConnection";
  items?: Array<{
    __typename: "Role";
    createdAt: string;
    modifiedAt: string;
    roleName: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListUsuarioHasRetosQuery = {
  __typename: "UsuarioRetoConnection";
  items?: Array<{
    __typename: "UsuarioReto";
    totEval?: number | null;
    numEval?: number | null;
    progress?: number | null;
    retoId: string;
    usuarioId: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListUsuarioRetosByUsuarioQuery = {
  __typename: "UsuarioRetoConnection";
  items?: Array<{
    __typename: "UsuarioReto";
    totEval?: number | null;
    numEval?: number | null;
    progress?: number | null;
    retoId: string;
    usuarioId: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListUsuariosQuery = {
  __typename: "UsuarioConnection";
  items?: Array<{
    __typename: "Usuario";
    createdAt: string;
    maxNew?: number | null;
    maxTotal?: number | null;
    modifiedAt: string;
    registered: boolean;
    timeZone: string;
    username: string;
    usuarioId: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ProgressAlgorithQuery = {
  __typename: "ProgressAlgorithOutput";
  dateLastUpdateLOC: string;
  dateNextEvaluationLOC: string;
  didPass: boolean;
  maxTimeNextEvaluation: number;
  msToRespondFirstOfTheDay: number;
  r: number;
  scoreFirstOfTheDay: number;
};

export type GetStatsByUsuarioQuery = {
  __typename: "Stats";
  cntDays?: number | null;
  cntRetosCompleted?: number | null;
  cntRetosCompletedToday?: number | null;
  cntEvalReady?: number | null;
  rachaDays?: number | null;
};

export type OnCreateAreaSubscription = {
  __typename: "Area";
  areaDescription?: string | null;
  areaId: string;
  areaImage?: string | null;
  areaName: string;
  createdAt: string;
  modifiedAt: string;
};

export type OnCreateEvaluationSubscription = {
  __typename: "Evaluation";
  createdAt: string;
  evaluationAnswerClassName: string;
  evaluationAnswerParameters: string;
  evaluationId: string;
  evaluationMsResponseTimeGoal: number;
  evaluationQuestionClassName: string;
  evaluationQuestionParameters: string;
  modifiedAt: string;
};

export type OnCreateProgressSubscription = {
  __typename: "Progress";
  createdAt: string;
  dateLastUpdateLOC?: string | null;
  dateNextEvaluationLOC?: string | null;
  didPass?: boolean | null;
  evaluationId: string;
  maxTimeNextEvaluation?: number | null;
  modifiedAt: string;
  msToRespondFirstOfTheDay?: number | null;
  r?: number | null;
  scoreFirstOfTheDay?: number | null;
  usuarioId: string;
};

export type OnCreateResponseSubscription = {
  __typename: "Response";
  agent: string;
  answerAsTxt: string;
  createdAt: string;
  didPass?: boolean | null;
  evaluationId: string;
  keyStrokes: string;
  msToRespond: number;
  numStops: number;
  questionAsTxt: string;
  responseAsTxt: string;
  score: number;
  usuarioId: string;
};

export type OnCreateRetoSubscription = {
  __typename: "Reto";
  areaId: string;
  createdAt: string;
  modifiedAt: string;
  retoDescription?: string | null;
  retoId: string;
  retoName: string;
  retoNumEvaluations: number;
};

export type OnCreateRoleSubscription = {
  __typename: "Role";
  createdAt: string;
  modifiedAt: string;
  roleName: string;
};

export type OnCreateUsuarioSubscription = {
  __typename: "Usuario";
  createdAt: string;
  maxNew?: number | null;
  maxTotal?: number | null;
  modifiedAt: string;
  registered: boolean;
  timeZone: string;
  username: string;
  usuarioId: string;
};

export type OnDeleteAreaSubscription = {
  __typename: "Area";
  areaDescription?: string | null;
  areaId: string;
  areaImage?: string | null;
  areaName: string;
  createdAt: string;
  modifiedAt: string;
};

export type OnDeleteEvaluationSubscription = {
  __typename: "Evaluation";
  createdAt: string;
  evaluationAnswerClassName: string;
  evaluationAnswerParameters: string;
  evaluationId: string;
  evaluationMsResponseTimeGoal: number;
  evaluationQuestionClassName: string;
  evaluationQuestionParameters: string;
  modifiedAt: string;
};

export type OnDeleteProgressSubscription = {
  __typename: "Progress";
  createdAt: string;
  dateLastUpdateLOC?: string | null;
  dateNextEvaluationLOC?: string | null;
  didPass?: boolean | null;
  evaluationId: string;
  maxTimeNextEvaluation?: number | null;
  modifiedAt: string;
  msToRespondFirstOfTheDay?: number | null;
  r?: number | null;
  scoreFirstOfTheDay?: number | null;
  usuarioId: string;
};

export type OnDeleteResponseSubscription = {
  __typename: "Response";
  agent: string;
  answerAsTxt: string;
  createdAt: string;
  didPass?: boolean | null;
  evaluationId: string;
  keyStrokes: string;
  msToRespond: number;
  numStops: number;
  questionAsTxt: string;
  responseAsTxt: string;
  score: number;
  usuarioId: string;
};

export type OnDeleteRetoSubscription = {
  __typename: "Reto";
  areaId: string;
  createdAt: string;
  modifiedAt: string;
  retoDescription?: string | null;
  retoId: string;
  retoName: string;
  retoNumEvaluations: number;
};

export type OnDeleteRoleSubscription = {
  __typename: "Role";
  createdAt: string;
  modifiedAt: string;
  roleName: string;
};

export type OnDeleteUsuarioSubscription = {
  __typename: "Usuario";
  createdAt: string;
  maxNew?: number | null;
  maxTotal?: number | null;
  modifiedAt: string;
  registered: boolean;
  timeZone: string;
  username: string;
  usuarioId: string;
};

export type OnUpdateAreaSubscription = {
  __typename: "Area";
  areaDescription?: string | null;
  areaId: string;
  areaImage?: string | null;
  areaName: string;
  createdAt: string;
  modifiedAt: string;
};

export type OnUpdateEvaluationSubscription = {
  __typename: "Evaluation";
  createdAt: string;
  evaluationAnswerClassName: string;
  evaluationAnswerParameters: string;
  evaluationId: string;
  evaluationMsResponseTimeGoal: number;
  evaluationQuestionClassName: string;
  evaluationQuestionParameters: string;
  modifiedAt: string;
};

export type OnUpdateProgressSubscription = {
  __typename: "Progress";
  createdAt: string;
  dateLastUpdateLOC?: string | null;
  dateNextEvaluationLOC?: string | null;
  didPass?: boolean | null;
  evaluationId: string;
  maxTimeNextEvaluation?: number | null;
  modifiedAt: string;
  msToRespondFirstOfTheDay?: number | null;
  r?: number | null;
  scoreFirstOfTheDay?: number | null;
  usuarioId: string;
};

export type OnUpdateResponseSubscription = {
  __typename: "Response";
  agent: string;
  answerAsTxt: string;
  createdAt: string;
  didPass?: boolean | null;
  evaluationId: string;
  keyStrokes: string;
  msToRespond: number;
  numStops: number;
  questionAsTxt: string;
  responseAsTxt: string;
  score: number;
  usuarioId: string;
};

export type OnUpdateRetoSubscription = {
  __typename: "Reto";
  areaId: string;
  createdAt: string;
  modifiedAt: string;
  retoDescription?: string | null;
  retoId: string;
  retoName: string;
  retoNumEvaluations: number;
};

export type OnUpdateUsuarioSubscription = {
  __typename: "Usuario";
  createdAt: string;
  maxNew?: number | null;
  maxTotal?: number | null;
  modifiedAt: string;
  registered: boolean;
  timeZone: string;
  username: string;
  usuarioId: string;
};

export type OncreateEvaluationHasPrerequisitSubscription = {
  __typename: "EvaluationPrerequisit";
  evaluationId: string;
  evaluationIdPre: string;
};

export type OncreateRetoHasEvaluationSubscription = {
  __typename: "RetoEvaluation";
  evaluationId: string;
  retoId: string;
};

export type OncreateUsuarioHasRetoSubscription = {
  __typename: "UsuarioReto";
  totEval?: number | null;
  numEval?: number | null;
  progress?: number | null;
  retoId: string;
  usuarioId: string;
};

export type OndeleteEvaluationHasPrerequisitSubscription = {
  __typename: "EvaluationPrerequisit";
  evaluationId: string;
  evaluationIdPre: string;
};

export type OndeleteRetoHasEvaluationSubscription = {
  __typename: "RetoEvaluation";
  evaluationId: string;
  retoId: string;
};

export type OndeleteUsuarioHasRetoSubscription = {
  __typename: "UsuarioReto";
  totEval?: number | null;
  numEval?: number | null;
  progress?: number | null;
  retoId: string;
  usuarioId: string;
};

export type OnupdateEvaluationHasPrerequisitSubscription = {
  __typename: "EvaluationPrerequisit";
  evaluationId: string;
  evaluationIdPre: string;
};

export type OnupdateUsuarioHasRetoSubscription = {
  __typename: "UsuarioReto";
  totEval?: number | null;
  numEval?: number | null;
  progress?: number | null;
  retoId: string;
  usuarioId: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateArea(input: CreateAreaInput): Promise<CreateAreaMutation> {
    const statement = `mutation CreateArea($input: CreateAreaInput!) {
        createArea(input: $input) {
          __typename
          areaDescription
          areaId
          areaImage
          areaName
          createdAt
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAreaMutation>response.data.createArea;
  }
  async CreateEvaluation(
    input: CreateEvaluationInput
  ): Promise<CreateEvaluationMutation> {
    const statement = `mutation CreateEvaluation($input: CreateEvaluationInput!) {
        createEvaluation(input: $input) {
          __typename
          createdAt
          evaluationAnswerClassName
          evaluationAnswerParameters
          evaluationId
          evaluationMsResponseTimeGoal
          evaluationQuestionClassName
          evaluationQuestionParameters
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEvaluationMutation>response.data.createEvaluation;
  }
  async CreateEvaluationHasPrerequisit(
    input: CreateEvaluationHasPrerequisitInput
  ): Promise<CreateEvaluationHasPrerequisitMutation> {
    const statement = `mutation CreateEvaluationHasPrerequisit($input: CreateEvaluationHasPrerequisitInput!) {
        createEvaluationHasPrerequisit(input: $input) {
          __typename
          evaluationId
          evaluationIdPre
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEvaluationHasPrerequisitMutation>(
      response.data.createEvaluationHasPrerequisit
    );
  }
  async CreateEvaluationWithReto(
    input: CreateEvaluationWithRetoInput
  ): Promise<CreateEvaluationWithRetoMutation> {
    const statement = `mutation CreateEvaluationWithReto($input: CreateEvaluationWithRetoInput!) {
        createEvaluationWithReto(input: $input) {
          __typename
          createdAt
          evaluationAnswerClassName
          evaluationAnswerParameters
          evaluationId
          evaluationMsResponseTimeGoal
          evaluationQuestionClassName
          evaluationQuestionParameters
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEvaluationWithRetoMutation>(
      response.data.createEvaluationWithReto
    );
  }
  async CreateProgress(
    input: CreateProgressInput
  ): Promise<CreateProgressMutation> {
    const statement = `mutation CreateProgress($input: CreateProgressInput!) {
        createProgress(input: $input) {
          __typename
          createdAt
          dateLastUpdateLOC
          dateNextEvaluationLOC
          didPass
          evaluationId
          maxTimeNextEvaluation
          modifiedAt
          msToRespondFirstOfTheDay
          r
          scoreFirstOfTheDay
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProgressMutation>response.data.createProgress;
  }
  async CreateResponse(
    input: CreateResponseInput
  ): Promise<CreateResponseMutation> {
    const statement = `mutation CreateResponse($input: CreateResponseInput!) {
        createResponse(input: $input) {
          __typename
          agent
          answerAsTxt
          createdAt
          didPass
          evaluationId
          keyStrokes
          msToRespond
          numStops
          questionAsTxt
          responseAsTxt
          score
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateResponseMutation>response.data.createResponse;
  }
  async CreateReto(input: CreateRetoInput): Promise<CreateRetoMutation> {
    const statement = `mutation CreateReto($input: CreateRetoInput!) {
        createReto(input: $input) {
          __typename
          areaId
          createdAt
          modifiedAt
          retoDescription
          retoId
          retoName
          retoNumEvaluations
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRetoMutation>response.data.createReto;
  }
  async CreateRetoHasEvaluation(
    input: CreateRetoHasEvaluationInput
  ): Promise<CreateRetoHasEvaluationMutation> {
    const statement = `mutation CreateRetoHasEvaluation($input: CreateRetoHasEvaluationInput!) {
        createRetoHasEvaluation(input: $input) {
          __typename
          evaluationId
          retoId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRetoHasEvaluationMutation>(
      response.data.createRetoHasEvaluation
    );
  }
  async CreateRole(input: CreateRoleInput): Promise<CreateRoleMutation> {
    const statement = `mutation CreateRole($input: CreateRoleInput!) {
        createRole(input: $input) {
          __typename
          createdAt
          modifiedAt
          roleName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRoleMutation>response.data.createRole;
  }
  async CreateUsuario(
    input: CreateUsuarioInput
  ): Promise<CreateUsuarioMutation> {
    const statement = `mutation CreateUsuario($input: CreateUsuarioInput!) {
        createUsuario(input: $input) {
          __typename
          createdAt
          maxNew
          maxTotal
          modifiedAt
          registered
          timeZone
          username
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUsuarioMutation>response.data.createUsuario;
  }
  async CreateUsuarioHasReto(
    input: CreateUsuarioHasRetoInput
  ): Promise<CreateUsuarioHasRetoMutation> {
    const statement = `mutation CreateUsuarioHasReto($input: CreateUsuarioHasRetoInput!) {
        createUsuarioHasReto(input: $input) {
          __typename
          totEval
          numEval
          progress
          retoId
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUsuarioHasRetoMutation>response.data.createUsuarioHasReto;
  }
  async DeleteArea(input: DeleteAreaInput): Promise<DeleteAreaMutation> {
    const statement = `mutation DeleteArea($input: DeleteAreaInput!) {
        deleteArea(input: $input) {
          __typename
          areaDescription
          areaId
          areaImage
          areaName
          createdAt
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAreaMutation>response.data.deleteArea;
  }
  async DeleteEvaluation(
    input: DeleteEvaluationInput
  ): Promise<DeleteEvaluationMutation> {
    const statement = `mutation DeleteEvaluation($input: DeleteEvaluationInput!) {
        deleteEvaluation(input: $input) {
          __typename
          createdAt
          evaluationAnswerClassName
          evaluationAnswerParameters
          evaluationId
          evaluationMsResponseTimeGoal
          evaluationQuestionClassName
          evaluationQuestionParameters
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEvaluationMutation>response.data.deleteEvaluation;
  }
  async DeleteEvaluationHasPrerequisit(
    input: DeleteEvaluationHasPrerequisitInput
  ): Promise<DeleteEvaluationHasPrerequisitMutation> {
    const statement = `mutation DeleteEvaluationHasPrerequisit($input: DeleteEvaluationHasPrerequisitInput!) {
        deleteEvaluationHasPrerequisit(input: $input) {
          __typename
          evaluationId
          evaluationIdPre
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEvaluationHasPrerequisitMutation>(
      response.data.deleteEvaluationHasPrerequisit
    );
  }
  async DeleteProgress(
    input: DeleteProgressInput
  ): Promise<DeleteProgressMutation> {
    const statement = `mutation DeleteProgress($input: DeleteProgressInput!) {
        deleteProgress(input: $input) {
          __typename
          createdAt
          dateLastUpdateLOC
          dateNextEvaluationLOC
          didPass
          evaluationId
          maxTimeNextEvaluation
          modifiedAt
          msToRespondFirstOfTheDay
          r
          scoreFirstOfTheDay
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProgressMutation>response.data.deleteProgress;
  }
  async DeleteResponse(
    input: DeleteResponseInput
  ): Promise<DeleteResponseMutation> {
    const statement = `mutation DeleteResponse($input: DeleteResponseInput!) {
        deleteResponse(input: $input) {
          __typename
          agent
          answerAsTxt
          createdAt
          didPass
          evaluationId
          keyStrokes
          msToRespond
          numStops
          questionAsTxt
          responseAsTxt
          score
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteResponseMutation>response.data.deleteResponse;
  }
  async DeleteReto(input: DeleteRetoInput): Promise<DeleteRetoMutation> {
    const statement = `mutation DeleteReto($input: DeleteRetoInput!) {
        deleteReto(input: $input) {
          __typename
          areaId
          createdAt
          modifiedAt
          retoDescription
          retoId
          retoName
          retoNumEvaluations
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRetoMutation>response.data.deleteReto;
  }
  async DeleteRetoHasEvaluation(
    input: DeleteRetoHasEvaluationInput
  ): Promise<DeleteRetoHasEvaluationMutation> {
    const statement = `mutation DeleteRetoHasEvaluation($input: DeleteRetoHasEvaluationInput!) {
        deleteRetoHasEvaluation(input: $input) {
          __typename
          evaluationId
          retoId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRetoHasEvaluationMutation>(
      response.data.deleteRetoHasEvaluation
    );
  }
  async DeleteRole(input: DeleteRoleInput): Promise<DeleteRoleMutation> {
    const statement = `mutation DeleteRole($input: DeleteRoleInput!) {
        deleteRole(input: $input) {
          __typename
          createdAt
          modifiedAt
          roleName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRoleMutation>response.data.deleteRole;
  }
  async DeleteUsuario(
    input: DeleteUsuarioInput
  ): Promise<DeleteUsuarioMutation> {
    const statement = `mutation DeleteUsuario($input: DeleteUsuarioInput!) {
        deleteUsuario(input: $input) {
          __typename
          createdAt
          maxNew
          maxTotal
          modifiedAt
          registered
          timeZone
          username
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUsuarioMutation>response.data.deleteUsuario;
  }
  async DeleteUsuarioHasReto(
    input: DeleteUsuarioHasRetoInput
  ): Promise<DeleteUsuarioHasRetoMutation> {
    const statement = `mutation DeleteUsuarioHasReto($input: DeleteUsuarioHasRetoInput!) {
        deleteUsuarioHasReto(input: $input) {
          __typename
          totEval
          numEval
          progress
          retoId
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUsuarioHasRetoMutation>response.data.deleteUsuarioHasReto;
  }
  async SendResponse(
    input: CreateResponseInput
  ): Promise<SendResponseMutation> {
    const statement = `mutation SendResponse($input: CreateResponseInput!) {
        sendResponse(input: $input) {
          __typename
          createdAt
          dateLastUpdateLOC
          dateNextEvaluationLOC
          didPass
          evaluationId
          maxTimeNextEvaluation
          modifiedAt
          msToRespondFirstOfTheDay
          r
          scoreFirstOfTheDay
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SendResponseMutation>response.data.sendResponse;
  }
  async UpdateArea(input: UpdateAreaInput): Promise<UpdateAreaMutation> {
    const statement = `mutation UpdateArea($input: UpdateAreaInput!) {
        updateArea(input: $input) {
          __typename
          areaDescription
          areaId
          areaImage
          areaName
          createdAt
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAreaMutation>response.data.updateArea;
  }
  async UpdateEvaluation(
    input: UpdateEvaluationInput
  ): Promise<UpdateEvaluationMutation> {
    const statement = `mutation UpdateEvaluation($input: UpdateEvaluationInput!) {
        updateEvaluation(input: $input) {
          __typename
          createdAt
          evaluationAnswerClassName
          evaluationAnswerParameters
          evaluationId
          evaluationMsResponseTimeGoal
          evaluationQuestionClassName
          evaluationQuestionParameters
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEvaluationMutation>response.data.updateEvaluation;
  }
  async UpdateEvaluationHasPrerequisit(
    input: UpdateEvaluationHasPrerequisitInput
  ): Promise<UpdateEvaluationHasPrerequisitMutation> {
    const statement = `mutation UpdateEvaluationHasPrerequisit($input: UpdateEvaluationHasPrerequisitInput!) {
        updateEvaluationHasPrerequisit(input: $input) {
          __typename
          evaluationId
          evaluationIdPre
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEvaluationHasPrerequisitMutation>(
      response.data.updateEvaluationHasPrerequisit
    );
  }
  async UpdateProgress(
    input: UpdateProgressInput
  ): Promise<UpdateProgressMutation> {
    const statement = `mutation UpdateProgress($input: UpdateProgressInput!) {
        updateProgress(input: $input) {
          __typename
          createdAt
          dateLastUpdateLOC
          dateNextEvaluationLOC
          didPass
          evaluationId
          maxTimeNextEvaluation
          modifiedAt
          msToRespondFirstOfTheDay
          r
          scoreFirstOfTheDay
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProgressMutation>response.data.updateProgress;
  }
  async UpdateResponse(
    input: UpdateResponseInput
  ): Promise<UpdateResponseMutation> {
    const statement = `mutation UpdateResponse($input: UpdateResponseInput!) {
        updateResponse(input: $input) {
          __typename
          agent
          answerAsTxt
          createdAt
          didPass
          evaluationId
          keyStrokes
          msToRespond
          numStops
          questionAsTxt
          responseAsTxt
          score
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateResponseMutation>response.data.updateResponse;
  }
  async UpdateReto(input: UpdateRetoInput): Promise<UpdateRetoMutation> {
    const statement = `mutation UpdateReto($input: UpdateRetoInput!) {
        updateReto(input: $input) {
          __typename
          areaId
          createdAt
          modifiedAt
          retoDescription
          retoId
          retoName
          retoNumEvaluations
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRetoMutation>response.data.updateReto;
  }
  async UpdateUsuario(
    input: UpdateUsuarioInput
  ): Promise<UpdateUsuarioMutation> {
    const statement = `mutation UpdateUsuario($input: UpdateUsuarioInput!) {
        updateUsuario(input: $input) {
          __typename
          createdAt
          maxNew
          maxTotal
          modifiedAt
          registered
          timeZone
          username
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUsuarioMutation>response.data.updateUsuario;
  }
  async UpdateUsuarioHasReto(
    input: UpdateUsuarioHasRetoInput
  ): Promise<UpdateUsuarioHasRetoMutation> {
    const statement = `mutation UpdateUsuarioHasReto($input: UpdateUsuarioHasRetoInput!) {
        updateUsuarioHasReto(input: $input) {
          __typename
          totEval
          numEval
          progress
          retoId
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUsuarioHasRetoMutation>response.data.updateUsuarioHasReto;
  }
  async GetArea(areaId: string): Promise<GetAreaQuery> {
    const statement = `query GetArea($areaId: ID!) {
        getArea(areaId: $areaId) {
          __typename
          areaDescription
          areaId
          areaImage
          areaName
          createdAt
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      areaId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAreaQuery>response.data.getArea;
  }
  async GetEvaluation(evaluationId: string): Promise<GetEvaluationQuery> {
    const statement = `query GetEvaluation($evaluationId: ID!) {
        getEvaluation(evaluationId: $evaluationId) {
          __typename
          createdAt
          evaluationAnswerClassName
          evaluationAnswerParameters
          evaluationId
          evaluationMsResponseTimeGoal
          evaluationQuestionClassName
          evaluationQuestionParameters
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      evaluationId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEvaluationQuery>response.data.getEvaluation;
  }
  async GetEvaluationHasPrerequisit(
    evaluationId: string,
    evaluationIdPre: string
  ): Promise<GetEvaluationHasPrerequisitQuery> {
    const statement = `query GetEvaluationHasPrerequisit($evaluationId: ID!, $evaluationIdPre: ID!) {
        getEvaluationHasPrerequisit(evaluationId: $evaluationId, evaluationIdPre: $evaluationIdPre) {
          __typename
          evaluationId
          evaluationIdPre
        }
      }`;
    const gqlAPIServiceArguments: any = {
      evaluationId,
      evaluationIdPre
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEvaluationHasPrerequisitQuery>(
      response.data.getEvaluationHasPrerequisit
    );
  }
  async GetProgress(
    evaluationId: string,
    usuarioId: string
  ): Promise<GetProgressQuery> {
    const statement = `query GetProgress($evaluationId: ID!, $usuarioId: ID!) {
        getProgress(evaluationId: $evaluationId, usuarioId: $usuarioId) {
          __typename
          createdAt
          dateLastUpdateLOC
          dateNextEvaluationLOC
          didPass
          evaluationId
          maxTimeNextEvaluation
          modifiedAt
          msToRespondFirstOfTheDay
          r
          scoreFirstOfTheDay
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      evaluationId,
      usuarioId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProgressQuery>response.data.getProgress;
  }
  async GetResponse(
    evaluationId: string,
    usuarioId: string
  ): Promise<GetResponseQuery> {
    const statement = `query GetResponse($evaluationId: ID!, $usuarioId: ID!) {
        getResponse(evaluationId: $evaluationId, usuarioId: $usuarioId) {
          __typename
          agent
          answerAsTxt
          createdAt
          didPass
          evaluationId
          keyStrokes
          msToRespond
          numStops
          questionAsTxt
          responseAsTxt
          score
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      evaluationId,
      usuarioId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetResponseQuery>response.data.getResponse;
  }
  async GetReto(retoId: string): Promise<GetRetoQuery> {
    const statement = `query GetReto($retoId: ID!) {
        getReto(retoId: $retoId) {
          __typename
          areaId
          createdAt
          modifiedAt
          retoDescription
          retoId
          retoName
          retoNumEvaluations
        }
      }`;
    const gqlAPIServiceArguments: any = {
      retoId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRetoQuery>response.data.getReto;
  }
  async GetRetoHasEvaluation(
    evaluationId: string,
    retoId: string
  ): Promise<GetRetoHasEvaluationQuery> {
    const statement = `query GetRetoHasEvaluation($evaluationId: ID!, $retoId: ID!) {
        getRetoHasEvaluation(evaluationId: $evaluationId, retoId: $retoId) {
          __typename
          evaluationId
          retoId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      evaluationId,
      retoId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRetoHasEvaluationQuery>response.data.getRetoHasEvaluation;
  }
  async GetRole(roleName: string): Promise<GetRoleQuery> {
    const statement = `query GetRole($roleName: String!) {
        getRole(roleName: $roleName) {
          __typename
          createdAt
          modifiedAt
          roleName
        }
      }`;
    const gqlAPIServiceArguments: any = {
      roleName
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRoleQuery>response.data.getRole;
  }
  async GetUsuario(usuarioId: string): Promise<GetUsuarioQuery> {
    const statement = `query GetUsuario($usuarioId: ID!) {
        getUsuario(usuarioId: $usuarioId) {
          __typename
          createdAt
          maxNew
          maxTotal
          modifiedAt
          registered
          timeZone
          username
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      usuarioId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUsuarioQuery>response.data.getUsuario;
  }
  async GetUsuarioHasReto(
    retoId: string,
    usuarioId: string
  ): Promise<GetUsuarioHasRetoQuery> {
    const statement = `query GetUsuarioHasReto($retoId: ID!, $usuarioId: ID!) {
        getUsuarioHasReto(retoId: $retoId, usuarioId: $usuarioId) {
          __typename
          totEval
          numEval
          progress
          retoId
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      retoId,
      usuarioId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUsuarioHasRetoQuery>response.data.getUsuarioHasReto;
  }
  async ListAreas(
    filter?: TableAreaFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAreasQuery> {
    const statement = `query ListAreas($filter: TableAreaFilterInput, $limit: Int, $nextToken: String) {
        listAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            areaDescription
            areaId
            areaImage
            areaName
            createdAt
            modifiedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAreasQuery>response.data.listAreas;
  }
  async ListEvaluationHasPrerequisits(
    filter?: TableEvaluationPrerequisitFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEvaluationHasPrerequisitsQuery> {
    const statement = `query ListEvaluationHasPrerequisits($filter: TableEvaluationPrerequisitFilterInput, $limit: Int, $nextToken: String) {
        listEvaluationHasPrerequisits(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            evaluationId
            evaluationIdPre
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEvaluationHasPrerequisitsQuery>(
      response.data.listEvaluationHasPrerequisits
    );
  }
  async ListEvaluations(
    filter?: TableEvaluationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEvaluationsQuery> {
    const statement = `query ListEvaluations($filter: TableEvaluationFilterInput, $limit: Int, $nextToken: String) {
        listEvaluations(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            evaluationAnswerClassName
            evaluationAnswerParameters
            evaluationId
            evaluationMsResponseTimeGoal
            evaluationQuestionClassName
            evaluationQuestionParameters
            modifiedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEvaluationsQuery>response.data.listEvaluations;
  }
  async ListEvaluationsByUsuarioAndReto(
    retoId: string,
    usuarioId: string
  ): Promise<ListEvaluationsByUsuarioAndRetoQuery> {
    const statement = `query ListEvaluationsByUsuarioAndReto($retoId: ID!, $usuarioId: ID!) {
        listEvaluationsByUsuarioAndReto(retoId: $retoId, usuarioId: $usuarioId) {
          __typename
          items {
            __typename
            createdAt
            evaluationAnswerClassName
            evaluationAnswerParameters
            evaluationId
            evaluationMsResponseTimeGoal
            evaluationQuestionClassName
            evaluationQuestionParameters
            modifiedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      retoId,
      usuarioId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEvaluationsByUsuarioAndRetoQuery>(
      response.data.listEvaluationsByUsuarioAndReto
    );
  }
  async ListEvaluationsByUsuarioAndRetoTest(
    retoId: string,
    usuarioId: string
  ): Promise<ListEvaluationsByUsuarioAndRetoTestQuery> {
    const statement = `query ListEvaluationsByUsuarioAndRetoTest($retoId: ID!, $usuarioId: ID!) {
        listEvaluationsByUsuarioAndRetoTest(retoId: $retoId, usuarioId: $usuarioId) {
          __typename
          items {
            __typename
            createdAt
            evaluationAnswerClassName
            evaluationAnswerParameters
            evaluationId
            evaluationMsResponseTimeGoal
            evaluationQuestionClassName
            evaluationQuestionParameters
            modifiedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      retoId,
      usuarioId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEvaluationsByUsuarioAndRetoTestQuery>(
      response.data.listEvaluationsByUsuarioAndRetoTest
    );
  }
  async ListEvaluationsForReto(
    retoId: string
  ): Promise<ListEvaluationsForRetoQuery> {
    const statement = `query ListEvaluationsForReto($retoId: ID!) {
        listEvaluationsForReto(retoId: $retoId) {
          __typename
          items {
            __typename
            createdAt
            evaluationAnswerClassName
            evaluationAnswerParameters
            evaluationId
            evaluationMsResponseTimeGoal
            evaluationQuestionClassName
            evaluationQuestionParameters
            modifiedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      retoId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEvaluationsForRetoQuery>response.data.listEvaluationsForReto;
  }
  async ListEvaluationsForRetoByUsuario(
    retoId: string,
    usuarioId: string
  ): Promise<ListEvaluationsForRetoByUsuarioQuery> {
    const statement = `query ListEvaluationsForRetoByUsuario($retoId: ID!, $usuarioId: ID!) {
        listEvaluationsForRetoByUsuario(retoId: $retoId, usuarioId: $usuarioId) {
          __typename
          items {
            __typename
            createdAt
            evaluationAnswerClassName
            evaluationAnswerParameters
            evaluationId
            evaluationMsResponseTimeGoal
            evaluationQuestionClassName
            evaluationQuestionParameters
            modifiedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      retoId,
      usuarioId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEvaluationsForRetoByUsuarioQuery>(
      response.data.listEvaluationsForRetoByUsuario
    );
  }
  async ListProgresses(
    filter?: TableProgressFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProgressesQuery> {
    const statement = `query ListProgresses($filter: TableProgressFilterInput, $limit: Int, $nextToken: String) {
        listProgresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            dateLastUpdateLOC
            dateNextEvaluationLOC
            didPass
            evaluationId
            maxTimeNextEvaluation
            modifiedAt
            msToRespondFirstOfTheDay
            r
            scoreFirstOfTheDay
            usuarioId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProgressesQuery>response.data.listProgresses;
  }
  async ListProgressesForUsuarioByReto(
    retoId: string,
    usuarioId: string
  ): Promise<ListProgressesForUsuarioByRetoQuery> {
    const statement = `query ListProgressesForUsuarioByReto($retoId: ID!, $usuarioId: ID!) {
        listProgressesForUsuarioByReto(retoId: $retoId, usuarioId: $usuarioId) {
          __typename
          items {
            __typename
            createdAt
            dateLastUpdateLOC
            dateNextEvaluationLOC
            didPass
            evaluationId
            maxTimeNextEvaluation
            modifiedAt
            msToRespondFirstOfTheDay
            r
            scoreFirstOfTheDay
            usuarioId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      retoId,
      usuarioId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProgressesForUsuarioByRetoQuery>(
      response.data.listProgressesForUsuarioByReto
    );
  }
  async ListResponses(
    filter?: TableResponseFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListResponsesQuery> {
    const statement = `query ListResponses($filter: TableResponseFilterInput, $limit: Int, $nextToken: String) {
        listResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            agent
            answerAsTxt
            createdAt
            didPass
            evaluationId
            keyStrokes
            msToRespond
            numStops
            questionAsTxt
            responseAsTxt
            score
            usuarioId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListResponsesQuery>response.data.listResponses;
  }
  async ListRetoHasEvaluations(
    filter?: TableRetoEvaluationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRetoHasEvaluationsQuery> {
    const statement = `query ListRetoHasEvaluations($filter: TableRetoEvaluationFilterInput, $limit: Int, $nextToken: String) {
        listRetoHasEvaluations(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            evaluationId
            retoId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRetoHasEvaluationsQuery>response.data.listRetoHasEvaluations;
  }
  async ListRetoHasEvaluationsByReto(
    retoId: string
  ): Promise<ListRetoHasEvaluationsByRetoQuery> {
    const statement = `query ListRetoHasEvaluationsByReto($retoId: ID!) {
        listRetoHasEvaluationsByReto(retoId: $retoId) {
          __typename
          items {
            __typename
            evaluationId
            retoId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      retoId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRetoHasEvaluationsByRetoQuery>(
      response.data.listRetoHasEvaluationsByReto
    );
  }
  async ListRetos(
    filter?: TableRetoFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRetosQuery> {
    const statement = `query ListRetos($filter: TableRetoFilterInput, $limit: Int, $nextToken: String) {
        listRetos(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            areaId
            createdAt
            modifiedAt
            retoDescription
            retoId
            retoName
            retoNumEvaluations
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRetosQuery>response.data.listRetos;
  }
  async ListRoles(
    filter?: TableRoleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRolesQuery> {
    const statement = `query ListRoles($filter: TableRoleFilterInput, $limit: Int, $nextToken: String) {
        listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            modifiedAt
            roleName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRolesQuery>response.data.listRoles;
  }
  async ListUsuarioHasRetos(
    filter?: TableUsuarioRetoFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsuarioHasRetosQuery> {
    const statement = `query ListUsuarioHasRetos($filter: TableUsuarioRetoFilterInput, $limit: Int, $nextToken: String) {
        listUsuarioHasRetos(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            totEval
            numEval
            progress
            retoId
            usuarioId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsuarioHasRetosQuery>response.data.listUsuarioHasRetos;
  }
  async ListUsuarioRetosByUsuario(
    usuarioId: string
  ): Promise<ListUsuarioRetosByUsuarioQuery> {
    const statement = `query ListUsuarioRetosByUsuario($usuarioId: ID!) {
        listUsuarioRetosByUsuario(usuarioId: $usuarioId) {
          __typename
          items {
            __typename
            totEval
            numEval
            progress
            retoId
            usuarioId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      usuarioId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsuarioRetosByUsuarioQuery>(
      response.data.listUsuarioRetosByUsuario
    );
  }
  async ListUsuarios(
    filter?: TableUsuarioFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsuariosQuery> {
    const statement = `query ListUsuarios($filter: TableUsuarioFilterInput, $limit: Int, $nextToken: String) {
        listUsuarios(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            createdAt
            maxNew
            maxTotal
            modifiedAt
            registered
            timeZone
            username
            usuarioId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsuariosQuery>response.data.listUsuarios;
  }
  async ProgressAlgorith(
    progressAlgorithInput: ProgressAlgorithInput
  ): Promise<ProgressAlgorithQuery> {
    const statement = `query ProgressAlgorith($progressAlgorithInput: ProgressAlgorithInput!) {
        progressAlgorith(progressAlgorithInput: $progressAlgorithInput) {
          __typename
          dateLastUpdateLOC
          dateNextEvaluationLOC
          didPass
          maxTimeNextEvaluation
          msToRespondFirstOfTheDay
          r
          scoreFirstOfTheDay
        }
      }`;
    const gqlAPIServiceArguments: any = {
      progressAlgorithInput
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ProgressAlgorithQuery>response.data.progressAlgorith;
  }
  async GetStatsByUsuario(usuarioId: string): Promise<GetStatsByUsuarioQuery> {
    const statement = `query GetStatsByUsuario($usuarioId: ID!) {
        getStatsByUsuario(usuarioId: $usuarioId) {
          __typename
          cntDays
          cntRetosCompleted
          cntRetosCompletedToday
          cntEvalReady
          rachaDays
        }
      }`;
    const gqlAPIServiceArguments: any = {
      usuarioId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetStatsByUsuarioQuery>response.data.getStatsByUsuario;
  }
  OnCreateAreaListener(
    areaDescription?: string,
    areaId?: string,
    areaImage?: string,
    areaName?: string,
    createdAt?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateArea">>
  > {
    const statement = `subscription OnCreateArea($areaDescription: String, $areaId: ID, $areaImage: String, $areaName: String, $createdAt: AWSDateTime) {
        onCreateArea(areaDescription: $areaDescription, areaId: $areaId, areaImage: $areaImage, areaName: $areaName, createdAt: $createdAt) {
          __typename
          areaDescription
          areaId
          areaImage
          areaName
          createdAt
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (areaDescription) {
      gqlAPIServiceArguments.areaDescription = areaDescription;
    }
    if (areaId) {
      gqlAPIServiceArguments.areaId = areaId;
    }
    if (areaImage) {
      gqlAPIServiceArguments.areaImage = areaImage;
    }
    if (areaName) {
      gqlAPIServiceArguments.areaName = areaName;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateArea">>
    >;
  }

  OnCreateEvaluationListener(
    evaluationAnswerClassName?: string,
    evaluationAnswerParameters?: string,
    evaluationId?: string,
    evaluationQuestionClassName?: string,
    evaluationQuestionParameters?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEvaluation">>
  > {
    const statement = `subscription OnCreateEvaluation($evaluationAnswerClassName: String, $evaluationAnswerParameters: AWSJSON, $evaluationId: ID, $evaluationQuestionClassName: String, $evaluationQuestionParameters: AWSJSON) {
        onCreateEvaluation(evaluationAnswerClassName: $evaluationAnswerClassName, evaluationAnswerParameters: $evaluationAnswerParameters, evaluationId: $evaluationId, evaluationQuestionClassName: $evaluationQuestionClassName, evaluationQuestionParameters: $evaluationQuestionParameters) {
          __typename
          createdAt
          evaluationAnswerClassName
          evaluationAnswerParameters
          evaluationId
          evaluationMsResponseTimeGoal
          evaluationQuestionClassName
          evaluationQuestionParameters
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (evaluationAnswerClassName) {
      gqlAPIServiceArguments.evaluationAnswerClassName = evaluationAnswerClassName;
    }
    if (evaluationAnswerParameters) {
      gqlAPIServiceArguments.evaluationAnswerParameters = evaluationAnswerParameters;
    }
    if (evaluationId) {
      gqlAPIServiceArguments.evaluationId = evaluationId;
    }
    if (evaluationQuestionClassName) {
      gqlAPIServiceArguments.evaluationQuestionClassName = evaluationQuestionClassName;
    }
    if (evaluationQuestionParameters) {
      gqlAPIServiceArguments.evaluationQuestionParameters = evaluationQuestionParameters;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEvaluation">>
    >;
  }

  OnCreateProgressListener(
    dateNextEvaluation?: string,
    evaluationId?: string,
    maxTimeNextEvaluation?: number,
    r?: number,
    usuarioId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProgress">>
  > {
    const statement = `subscription OnCreateProgress($dateNextEvaluation: AWSDate, $evaluationId: ID, $maxTimeNextEvaluation: Int, $r: Float, $usuarioId: ID) {
        onCreateProgress(dateNextEvaluation: $dateNextEvaluation, evaluationId: $evaluationId, maxTimeNextEvaluation: $maxTimeNextEvaluation, r: $r, usuarioId: $usuarioId) {
          __typename
          createdAt
          dateLastUpdateLOC
          dateNextEvaluationLOC
          didPass
          evaluationId
          maxTimeNextEvaluation
          modifiedAt
          msToRespondFirstOfTheDay
          r
          scoreFirstOfTheDay
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (dateNextEvaluation) {
      gqlAPIServiceArguments.dateNextEvaluation = dateNextEvaluation;
    }
    if (evaluationId) {
      gqlAPIServiceArguments.evaluationId = evaluationId;
    }
    if (maxTimeNextEvaluation) {
      gqlAPIServiceArguments.maxTimeNextEvaluation = maxTimeNextEvaluation;
    }
    if (r) {
      gqlAPIServiceArguments.r = r;
    }
    if (usuarioId) {
      gqlAPIServiceArguments.usuarioId = usuarioId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateProgress">>
    >;
  }

  OnCreateResponseListener(
    answerAsTxt?: string,
    createdAt?: string,
    evaluationId?: string,
    questionAsTxt?: string,
    usuarioId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateResponse">>
  > {
    const statement = `subscription OnCreateResponse($answerAsTxt: String, $createdAt: AWSDateTime, $evaluationId: ID, $questionAsTxt: String, $usuarioId: ID) {
        onCreateResponse(answerAsTxt: $answerAsTxt, createdAt: $createdAt, evaluationId: $evaluationId, questionAsTxt: $questionAsTxt, usuarioId: $usuarioId) {
          __typename
          agent
          answerAsTxt
          createdAt
          didPass
          evaluationId
          keyStrokes
          msToRespond
          numStops
          questionAsTxt
          responseAsTxt
          score
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (answerAsTxt) {
      gqlAPIServiceArguments.answerAsTxt = answerAsTxt;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (evaluationId) {
      gqlAPIServiceArguments.evaluationId = evaluationId;
    }
    if (questionAsTxt) {
      gqlAPIServiceArguments.questionAsTxt = questionAsTxt;
    }
    if (usuarioId) {
      gqlAPIServiceArguments.usuarioId = usuarioId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateResponse">>
    >;
  }

  OnCreateRetoListener(
    areaId?: string,
    createdAt?: string,
    retoDescription?: string,
    retoId?: string,
    retoName?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateReto">>
  > {
    const statement = `subscription OnCreateReto($areaId: ID, $createdAt: AWSDateTime, $retoDescription: String, $retoId: ID, $retoName: String) {
        onCreateReto(areaId: $areaId, createdAt: $createdAt, retoDescription: $retoDescription, retoId: $retoId, retoName: $retoName) {
          __typename
          areaId
          createdAt
          modifiedAt
          retoDescription
          retoId
          retoName
          retoNumEvaluations
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (areaId) {
      gqlAPIServiceArguments.areaId = areaId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (retoDescription) {
      gqlAPIServiceArguments.retoDescription = retoDescription;
    }
    if (retoId) {
      gqlAPIServiceArguments.retoId = retoId;
    }
    if (retoName) {
      gqlAPIServiceArguments.retoName = retoName;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateReto">>
    >;
  }

  OnCreateRoleListener(
    createdAt?: string,
    modifiedAt?: string,
    roleName?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateRole">>
  > {
    const statement = `subscription OnCreateRole($createdAt: AWSDateTime, $modifiedAt: AWSDateTime, $roleName: String) {
        onCreateRole(createdAt: $createdAt, modifiedAt: $modifiedAt, roleName: $roleName) {
          __typename
          createdAt
          modifiedAt
          roleName
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (modifiedAt) {
      gqlAPIServiceArguments.modifiedAt = modifiedAt;
    }
    if (roleName) {
      gqlAPIServiceArguments.roleName = roleName;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateRole">>
    >;
  }

  OnCreateUsuarioListener(
    maxNew?: number,
    registered?: boolean,
    timeZone?: string,
    username?: string,
    usuarioId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUsuario">>
  > {
    const statement = `subscription OnCreateUsuario($maxNew: Int, $registered: Boolean, $timeZone: String, $username: String, $usuarioId: ID) {
        onCreateUsuario(maxNew: $maxNew, registered: $registered, timeZone: $timeZone, username: $username, usuarioId: $usuarioId) {
          __typename
          createdAt
          maxNew
          maxTotal
          modifiedAt
          registered
          timeZone
          username
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (maxNew) {
      gqlAPIServiceArguments.maxNew = maxNew;
    }
    if (registered) {
      gqlAPIServiceArguments.registered = registered;
    }
    if (timeZone) {
      gqlAPIServiceArguments.timeZone = timeZone;
    }
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (usuarioId) {
      gqlAPIServiceArguments.usuarioId = usuarioId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateUsuario">>
    >;
  }

  OnDeleteAreaListener(
    areaDescription?: string,
    areaId?: string,
    areaImage?: string,
    areaName?: string,
    createdAt?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteArea">>
  > {
    const statement = `subscription OnDeleteArea($areaDescription: String, $areaId: ID, $areaImage: String, $areaName: String, $createdAt: AWSDateTime) {
        onDeleteArea(areaDescription: $areaDescription, areaId: $areaId, areaImage: $areaImage, areaName: $areaName, createdAt: $createdAt) {
          __typename
          areaDescription
          areaId
          areaImage
          areaName
          createdAt
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (areaDescription) {
      gqlAPIServiceArguments.areaDescription = areaDescription;
    }
    if (areaId) {
      gqlAPIServiceArguments.areaId = areaId;
    }
    if (areaImage) {
      gqlAPIServiceArguments.areaImage = areaImage;
    }
    if (areaName) {
      gqlAPIServiceArguments.areaName = areaName;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteArea">>
    >;
  }

  OnDeleteEvaluationListener(
    evaluationAnswerClassName?: string,
    evaluationAnswerParameters?: string,
    evaluationId?: string,
    evaluationQuestionClassName?: string,
    evaluationQuestionParameters?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEvaluation">>
  > {
    const statement = `subscription OnDeleteEvaluation($evaluationAnswerClassName: String, $evaluationAnswerParameters: AWSJSON, $evaluationId: ID, $evaluationQuestionClassName: String, $evaluationQuestionParameters: AWSJSON) {
        onDeleteEvaluation(evaluationAnswerClassName: $evaluationAnswerClassName, evaluationAnswerParameters: $evaluationAnswerParameters, evaluationId: $evaluationId, evaluationQuestionClassName: $evaluationQuestionClassName, evaluationQuestionParameters: $evaluationQuestionParameters) {
          __typename
          createdAt
          evaluationAnswerClassName
          evaluationAnswerParameters
          evaluationId
          evaluationMsResponseTimeGoal
          evaluationQuestionClassName
          evaluationQuestionParameters
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (evaluationAnswerClassName) {
      gqlAPIServiceArguments.evaluationAnswerClassName = evaluationAnswerClassName;
    }
    if (evaluationAnswerParameters) {
      gqlAPIServiceArguments.evaluationAnswerParameters = evaluationAnswerParameters;
    }
    if (evaluationId) {
      gqlAPIServiceArguments.evaluationId = evaluationId;
    }
    if (evaluationQuestionClassName) {
      gqlAPIServiceArguments.evaluationQuestionClassName = evaluationQuestionClassName;
    }
    if (evaluationQuestionParameters) {
      gqlAPIServiceArguments.evaluationQuestionParameters = evaluationQuestionParameters;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEvaluation">>
    >;
  }

  OnDeleteProgressListener(
    dateNextEvaluation?: string,
    evaluationId?: string,
    maxTimeNextEvaluation?: number,
    r?: number,
    usuarioId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProgress">>
  > {
    const statement = `subscription OnDeleteProgress($dateNextEvaluation: AWSDate, $evaluationId: ID, $maxTimeNextEvaluation: Int, $r: Float, $usuarioId: ID) {
        onDeleteProgress(dateNextEvaluation: $dateNextEvaluation, evaluationId: $evaluationId, maxTimeNextEvaluation: $maxTimeNextEvaluation, r: $r, usuarioId: $usuarioId) {
          __typename
          createdAt
          dateLastUpdateLOC
          dateNextEvaluationLOC
          didPass
          evaluationId
          maxTimeNextEvaluation
          modifiedAt
          msToRespondFirstOfTheDay
          r
          scoreFirstOfTheDay
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (dateNextEvaluation) {
      gqlAPIServiceArguments.dateNextEvaluation = dateNextEvaluation;
    }
    if (evaluationId) {
      gqlAPIServiceArguments.evaluationId = evaluationId;
    }
    if (maxTimeNextEvaluation) {
      gqlAPIServiceArguments.maxTimeNextEvaluation = maxTimeNextEvaluation;
    }
    if (r) {
      gqlAPIServiceArguments.r = r;
    }
    if (usuarioId) {
      gqlAPIServiceArguments.usuarioId = usuarioId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteProgress">>
    >;
  }

  OnDeleteResponseListener(
    answerAsTxt?: string,
    createdAt?: string,
    evaluationId?: string,
    questionAsTxt?: string,
    usuarioId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteResponse">>
  > {
    const statement = `subscription OnDeleteResponse($answerAsTxt: String, $createdAt: AWSDateTime, $evaluationId: ID, $questionAsTxt: String, $usuarioId: ID) {
        onDeleteResponse(answerAsTxt: $answerAsTxt, createdAt: $createdAt, evaluationId: $evaluationId, questionAsTxt: $questionAsTxt, usuarioId: $usuarioId) {
          __typename
          agent
          answerAsTxt
          createdAt
          didPass
          evaluationId
          keyStrokes
          msToRespond
          numStops
          questionAsTxt
          responseAsTxt
          score
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (answerAsTxt) {
      gqlAPIServiceArguments.answerAsTxt = answerAsTxt;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (evaluationId) {
      gqlAPIServiceArguments.evaluationId = evaluationId;
    }
    if (questionAsTxt) {
      gqlAPIServiceArguments.questionAsTxt = questionAsTxt;
    }
    if (usuarioId) {
      gqlAPIServiceArguments.usuarioId = usuarioId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteResponse">>
    >;
  }

  OnDeleteRetoListener(
    areaId?: string,
    createdAt?: string,
    retoDescription?: string,
    retoId?: string,
    retoName?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteReto">>
  > {
    const statement = `subscription OnDeleteReto($areaId: ID, $createdAt: AWSDateTime, $retoDescription: String, $retoId: ID, $retoName: String) {
        onDeleteReto(areaId: $areaId, createdAt: $createdAt, retoDescription: $retoDescription, retoId: $retoId, retoName: $retoName) {
          __typename
          areaId
          createdAt
          modifiedAt
          retoDescription
          retoId
          retoName
          retoNumEvaluations
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (areaId) {
      gqlAPIServiceArguments.areaId = areaId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (retoDescription) {
      gqlAPIServiceArguments.retoDescription = retoDescription;
    }
    if (retoId) {
      gqlAPIServiceArguments.retoId = retoId;
    }
    if (retoName) {
      gqlAPIServiceArguments.retoName = retoName;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteReto">>
    >;
  }

  OnDeleteRoleListener(
    createdAt?: string,
    modifiedAt?: string,
    roleName?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteRole">>
  > {
    const statement = `subscription OnDeleteRole($createdAt: AWSDateTime, $modifiedAt: AWSDateTime, $roleName: String) {
        onDeleteRole(createdAt: $createdAt, modifiedAt: $modifiedAt, roleName: $roleName) {
          __typename
          createdAt
          modifiedAt
          roleName
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (modifiedAt) {
      gqlAPIServiceArguments.modifiedAt = modifiedAt;
    }
    if (roleName) {
      gqlAPIServiceArguments.roleName = roleName;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteRole">>
    >;
  }

  OnDeleteUsuarioListener(
    maxNew?: number,
    registered?: boolean,
    timeZone?: string,
    username?: string,
    usuarioId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUsuario">>
  > {
    const statement = `subscription OnDeleteUsuario($maxNew: Int, $registered: Boolean, $timeZone: String, $username: String, $usuarioId: ID) {
        onDeleteUsuario(maxNew: $maxNew, registered: $registered, timeZone: $timeZone, username: $username, usuarioId: $usuarioId) {
          __typename
          createdAt
          maxNew
          maxTotal
          modifiedAt
          registered
          timeZone
          username
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (maxNew) {
      gqlAPIServiceArguments.maxNew = maxNew;
    }
    if (registered) {
      gqlAPIServiceArguments.registered = registered;
    }
    if (timeZone) {
      gqlAPIServiceArguments.timeZone = timeZone;
    }
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (usuarioId) {
      gqlAPIServiceArguments.usuarioId = usuarioId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteUsuario">>
    >;
  }

  OnUpdateAreaListener(
    areaDescription?: string,
    areaId?: string,
    areaImage?: string,
    areaName?: string,
    createdAt?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateArea">>
  > {
    const statement = `subscription OnUpdateArea($areaDescription: String, $areaId: ID, $areaImage: String, $areaName: String, $createdAt: AWSDateTime) {
        onUpdateArea(areaDescription: $areaDescription, areaId: $areaId, areaImage: $areaImage, areaName: $areaName, createdAt: $createdAt) {
          __typename
          areaDescription
          areaId
          areaImage
          areaName
          createdAt
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (areaDescription) {
      gqlAPIServiceArguments.areaDescription = areaDescription;
    }
    if (areaId) {
      gqlAPIServiceArguments.areaId = areaId;
    }
    if (areaImage) {
      gqlAPIServiceArguments.areaImage = areaImage;
    }
    if (areaName) {
      gqlAPIServiceArguments.areaName = areaName;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateArea">>
    >;
  }

  OnUpdateEvaluationListener(
    evaluationAnswerClassName?: string,
    evaluationAnswerParameters?: string,
    evaluationId?: string,
    evaluationQuestionClassName?: string,
    evaluationQuestionParameters?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEvaluation">>
  > {
    const statement = `subscription OnUpdateEvaluation($evaluationAnswerClassName: String, $evaluationAnswerParameters: AWSJSON, $evaluationId: ID, $evaluationQuestionClassName: String, $evaluationQuestionParameters: AWSJSON) {
        onUpdateEvaluation(evaluationAnswerClassName: $evaluationAnswerClassName, evaluationAnswerParameters: $evaluationAnswerParameters, evaluationId: $evaluationId, evaluationQuestionClassName: $evaluationQuestionClassName, evaluationQuestionParameters: $evaluationQuestionParameters) {
          __typename
          createdAt
          evaluationAnswerClassName
          evaluationAnswerParameters
          evaluationId
          evaluationMsResponseTimeGoal
          evaluationQuestionClassName
          evaluationQuestionParameters
          modifiedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (evaluationAnswerClassName) {
      gqlAPIServiceArguments.evaluationAnswerClassName = evaluationAnswerClassName;
    }
    if (evaluationAnswerParameters) {
      gqlAPIServiceArguments.evaluationAnswerParameters = evaluationAnswerParameters;
    }
    if (evaluationId) {
      gqlAPIServiceArguments.evaluationId = evaluationId;
    }
    if (evaluationQuestionClassName) {
      gqlAPIServiceArguments.evaluationQuestionClassName = evaluationQuestionClassName;
    }
    if (evaluationQuestionParameters) {
      gqlAPIServiceArguments.evaluationQuestionParameters = evaluationQuestionParameters;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEvaluation">>
    >;
  }

  OnUpdateProgressListener(
    dateNextEvaluation?: string,
    evaluationId?: string,
    maxTimeNextEvaluation?: number,
    r?: number,
    usuarioId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProgress">>
  > {
    const statement = `subscription OnUpdateProgress($dateNextEvaluation: AWSDate, $evaluationId: ID, $maxTimeNextEvaluation: Int, $r: Float, $usuarioId: ID) {
        onUpdateProgress(dateNextEvaluation: $dateNextEvaluation, evaluationId: $evaluationId, maxTimeNextEvaluation: $maxTimeNextEvaluation, r: $r, usuarioId: $usuarioId) {
          __typename
          createdAt
          dateLastUpdateLOC
          dateNextEvaluationLOC
          didPass
          evaluationId
          maxTimeNextEvaluation
          modifiedAt
          msToRespondFirstOfTheDay
          r
          scoreFirstOfTheDay
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (dateNextEvaluation) {
      gqlAPIServiceArguments.dateNextEvaluation = dateNextEvaluation;
    }
    if (evaluationId) {
      gqlAPIServiceArguments.evaluationId = evaluationId;
    }
    if (maxTimeNextEvaluation) {
      gqlAPIServiceArguments.maxTimeNextEvaluation = maxTimeNextEvaluation;
    }
    if (r) {
      gqlAPIServiceArguments.r = r;
    }
    if (usuarioId) {
      gqlAPIServiceArguments.usuarioId = usuarioId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateProgress">>
    >;
  }

  OnUpdateResponseListener(
    answerAsTxt?: string,
    createdAt?: string,
    evaluationId?: string,
    questionAsTxt?: string,
    usuarioId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateResponse">>
  > {
    const statement = `subscription OnUpdateResponse($answerAsTxt: String, $createdAt: AWSDateTime, $evaluationId: ID, $questionAsTxt: String, $usuarioId: ID) {
        onUpdateResponse(answerAsTxt: $answerAsTxt, createdAt: $createdAt, evaluationId: $evaluationId, questionAsTxt: $questionAsTxt, usuarioId: $usuarioId) {
          __typename
          agent
          answerAsTxt
          createdAt
          didPass
          evaluationId
          keyStrokes
          msToRespond
          numStops
          questionAsTxt
          responseAsTxt
          score
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (answerAsTxt) {
      gqlAPIServiceArguments.answerAsTxt = answerAsTxt;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (evaluationId) {
      gqlAPIServiceArguments.evaluationId = evaluationId;
    }
    if (questionAsTxt) {
      gqlAPIServiceArguments.questionAsTxt = questionAsTxt;
    }
    if (usuarioId) {
      gqlAPIServiceArguments.usuarioId = usuarioId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateResponse">>
    >;
  }

  OnUpdateRetoListener(
    areaId?: string,
    createdAt?: string,
    retoDescription?: string,
    retoId?: string,
    retoName?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateReto">>
  > {
    const statement = `subscription OnUpdateReto($areaId: ID, $createdAt: AWSDateTime, $retoDescription: String, $retoId: ID, $retoName: String) {
        onUpdateReto(areaId: $areaId, createdAt: $createdAt, retoDescription: $retoDescription, retoId: $retoId, retoName: $retoName) {
          __typename
          areaId
          createdAt
          modifiedAt
          retoDescription
          retoId
          retoName
          retoNumEvaluations
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (areaId) {
      gqlAPIServiceArguments.areaId = areaId;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (retoDescription) {
      gqlAPIServiceArguments.retoDescription = retoDescription;
    }
    if (retoId) {
      gqlAPIServiceArguments.retoId = retoId;
    }
    if (retoName) {
      gqlAPIServiceArguments.retoName = retoName;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateReto">>
    >;
  }

  OnUpdateUsuarioListener(
    maxNew?: number,
    registered?: boolean,
    timeZone?: string,
    username?: string,
    usuarioId?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUsuario">>
  > {
    const statement = `subscription OnUpdateUsuario($maxNew: Int, $registered: Boolean, $timeZone: String, $username: String, $usuarioId: ID) {
        onUpdateUsuario(maxNew: $maxNew, registered: $registered, timeZone: $timeZone, username: $username, usuarioId: $usuarioId) {
          __typename
          createdAt
          maxNew
          maxTotal
          modifiedAt
          registered
          timeZone
          username
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (maxNew) {
      gqlAPIServiceArguments.maxNew = maxNew;
    }
    if (registered) {
      gqlAPIServiceArguments.registered = registered;
    }
    if (timeZone) {
      gqlAPIServiceArguments.timeZone = timeZone;
    }
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (usuarioId) {
      gqlAPIServiceArguments.usuarioId = usuarioId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateUsuario">>
    >;
  }

  OncreateEvaluationHasPrerequisitListener(
    evaluationId?: string,
    evaluationIdPre?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "oncreateEvaluationHasPrerequisit">
    >
  > {
    const statement = `subscription OncreateEvaluationHasPrerequisit($evaluationId: ID, $evaluationIdPre: ID) {
        oncreateEvaluationHasPrerequisit(evaluationId: $evaluationId, evaluationIdPre: $evaluationIdPre) {
          __typename
          evaluationId
          evaluationIdPre
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (evaluationId) {
      gqlAPIServiceArguments.evaluationId = evaluationId;
    }
    if (evaluationIdPre) {
      gqlAPIServiceArguments.evaluationIdPre = evaluationIdPre;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "oncreateEvaluationHasPrerequisit">
      >
    >;
  }

  OncreateRetoHasEvaluationListener(
    evaluationId?: string,
    retoId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "oncreateRetoHasEvaluation">
    >
  > {
    const statement = `subscription OncreateRetoHasEvaluation($evaluationId: ID, $retoId: ID) {
        oncreateRetoHasEvaluation(evaluationId: $evaluationId, retoId: $retoId) {
          __typename
          evaluationId
          retoId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (evaluationId) {
      gqlAPIServiceArguments.evaluationId = evaluationId;
    }
    if (retoId) {
      gqlAPIServiceArguments.retoId = retoId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "oncreateRetoHasEvaluation">
      >
    >;
  }

  OncreateUsuarioHasRetoListener(
    retoId?: string,
    usuarioId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "oncreateUsuarioHasReto">
    >
  > {
    const statement = `subscription OncreateUsuarioHasReto($retoId: ID, $usuarioId: ID) {
        oncreateUsuarioHasReto(retoId: $retoId, usuarioId: $usuarioId) {
          __typename
          totEval
          numEval
          progress
          retoId
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (retoId) {
      gqlAPIServiceArguments.retoId = retoId;
    }
    if (usuarioId) {
      gqlAPIServiceArguments.usuarioId = usuarioId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "oncreateUsuarioHasReto">
      >
    >;
  }

  OndeleteEvaluationHasPrerequisitListener(
    evaluationId?: string,
    evaluationIdPre?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "ondeleteEvaluationHasPrerequisit">
    >
  > {
    const statement = `subscription OndeleteEvaluationHasPrerequisit($evaluationId: ID, $evaluationIdPre: ID) {
        ondeleteEvaluationHasPrerequisit(evaluationId: $evaluationId, evaluationIdPre: $evaluationIdPre) {
          __typename
          evaluationId
          evaluationIdPre
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (evaluationId) {
      gqlAPIServiceArguments.evaluationId = evaluationId;
    }
    if (evaluationIdPre) {
      gqlAPIServiceArguments.evaluationIdPre = evaluationIdPre;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "ondeleteEvaluationHasPrerequisit">
      >
    >;
  }

  OndeleteRetoHasEvaluationListener(
    evaluationId?: string,
    retoId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "ondeleteRetoHasEvaluation">
    >
  > {
    const statement = `subscription OndeleteRetoHasEvaluation($evaluationId: ID, $retoId: ID) {
        ondeleteRetoHasEvaluation(evaluationId: $evaluationId, retoId: $retoId) {
          __typename
          evaluationId
          retoId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (evaluationId) {
      gqlAPIServiceArguments.evaluationId = evaluationId;
    }
    if (retoId) {
      gqlAPIServiceArguments.retoId = retoId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "ondeleteRetoHasEvaluation">
      >
    >;
  }

  OndeleteUsuarioHasRetoListener(
    retoId?: string,
    usuarioId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "ondeleteUsuarioHasReto">
    >
  > {
    const statement = `subscription OndeleteUsuarioHasReto($retoId: ID, $usuarioId: ID) {
        ondeleteUsuarioHasReto(retoId: $retoId, usuarioId: $usuarioId) {
          __typename
          totEval
          numEval
          progress
          retoId
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (retoId) {
      gqlAPIServiceArguments.retoId = retoId;
    }
    if (usuarioId) {
      gqlAPIServiceArguments.usuarioId = usuarioId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "ondeleteUsuarioHasReto">
      >
    >;
  }

  OnupdateEvaluationHasPrerequisitListener(
    evaluationId?: string,
    evaluationIdPre?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onupdateEvaluationHasPrerequisit">
    >
  > {
    const statement = `subscription OnupdateEvaluationHasPrerequisit($evaluationId: ID, $evaluationIdPre: ID) {
        onupdateEvaluationHasPrerequisit(evaluationId: $evaluationId, evaluationIdPre: $evaluationIdPre) {
          __typename
          evaluationId
          evaluationIdPre
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (evaluationId) {
      gqlAPIServiceArguments.evaluationId = evaluationId;
    }
    if (evaluationIdPre) {
      gqlAPIServiceArguments.evaluationIdPre = evaluationIdPre;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onupdateEvaluationHasPrerequisit">
      >
    >;
  }

  OnupdateUsuarioHasRetoListener(
    retoId?: string,
    usuarioId?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onupdateUsuarioHasReto">
    >
  > {
    const statement = `subscription OnupdateUsuarioHasReto($retoId: ID, $usuarioId: ID) {
        onupdateUsuarioHasReto(retoId: $retoId, usuarioId: $usuarioId) {
          __typename
          totEval
          numEval
          progress
          retoId
          usuarioId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (retoId) {
      gqlAPIServiceArguments.retoId = retoId;
    }
    if (usuarioId) {
      gqlAPIServiceArguments.usuarioId = usuarioId;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onupdateUsuarioHasReto">
      >
    >;
  }
}
