import { EvaluInterface } from "./evalu-interface.model";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

export class EvaluTrigonometry implements EvaluInterface {
  private id: string;
  private rt: number;
  private r: string;
  private rMin: number;
  private rMax: number;
  private alfaMin: number;
  private alfaMax: number;
  private thMin: number;
  private thMax: number;
  private circleVisible: boolean;
  private axisVisible: boolean;
  private scaleTriangle: boolean;
  private q: string;

  private question;
  private asw: string;

  private sanitizer: DomSanitizer;
  svg: SafeHtml;

  constructor(
    _id: string,
    _rt: number,
    _r: string,
    _rMin: number,
    _rMax: number,
    _alfaMin: number,
    _alfaMax: number,
    _thMin: number,
    _thMax: number,
    _circleVisible: boolean,
    _axisVisible: boolean,
    _scaleTriangle: boolean,
    _q: string
  ) {
    this.id = _id;
    this.rt = _rt;

    this.r = _r;
    this.rMin = _rMin;
    this.rMax = _rMax;
    this.alfaMin = _alfaMin;
    this.alfaMax = _alfaMax;
    this.thMin = _thMin;
    this.thMax = _thMax;
    this.circleVisible = _circleVisible;
    this.axisVisible = _axisVisible;
    this.scaleTriangle = _scaleTriangle;
    this.q = _q;
  }

  getId(): string {
    return this.id;
  }

  getQNew() {
    const ig = new ImageGenerator();
    var theSVGimg = ig.generateImage(
        this.r,
        this.rMin,
        this.rMax,
        this.alfaMin,
        this.alfaMax,
        this.thMin,
        this.thMax,
        this.circleVisible,
        this.axisVisible,
        this.scaleTriangle,
        this.q
      )
    this.question = {
      img: theSVGimg,
      q: ig.getQuestion(),
      a: ig.getAnswer()
    };
    this.asw = ig.getAnswer();
    return JSON.stringify(this.question);
  }

  getQ(): string {
    return this.question;
  }

  getQShort(): string {
    return "Img:" + this.asw;
  }

  getA(): string {
    return this.asw;
  }

  gradeAnswer(answer: string): number {
    console.log(answer);
    console.log(this.asw.length);
    var g = 1 - this.levenshteinDistance(this.asw, answer) / this.asw.length;
    return g < 0 ? 0 : g;
  }

  getTimeToRespondMs() {
    return this.rt;
  }

  getEvalDisplay() {
    return "trigonometryDisplay";
  }

  levenshteinDistance(a: string, b: string) {
    if (a.length == 0) return b.length;
    if (b.length == 0) return a.length;

    var matrix = [];

    // increment along the first column of each row
    var i: number;
    for (i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    // increment each column in the first row
    var j: number;
    for (j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (i = 1; i <= b.length; i++) {
      for (j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) == a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            Math.min(
              matrix[i][j - 1] + 1, // insertion
              matrix[i - 1][j] + 1
            )
          ); // deletion
        }
      }
    }

    return matrix[b.length][a.length];
  }
}

/*
  Desde aquí comienaza códibo para crear gráfico dinámico
*/



class Point {

    static oXAxis: number = 150.0;
    static oYAxis: number = 125.0;
    static rCircleInImage: number = 100.0;

    x: number;
    y: number;
    xth: number;
    yth: number;
    th: number;

    constructor(x: number, y: number, th: number) {
        this.x = x;
        this.y = y;
        this.th = th;
        this.xth = Math.cos(th) * x - Math.sin(th) * y;
        this.yth = Math.sin(th) * x + Math.cos(th) * y;
    }

    X(): number {
        return this.x;
    }
    
    Y(): number {
        return this.y;
    }

    XImgO(): number {
        return this.transformToImg(this.x, Point.oXAxis, 100);
    }
    
    YImgO(): number {
        return this.transformToImg(this.y, Point.oYAxis, -100);
    }

    XImg(): number {
        return this.transformToImg(this.xth, Point.oXAxis, 100);
    }
    
    YImg(): number {
        return this.transformToImg(this.yth, Point.oYAxis, -100);
    }

    private transformToImg(v: number, oAxis: number, scale: number): number {
        return oAxis + scale * v;
    }

    public static scaleBackX(xImg:number): number {
        return (xImg - this.oXAxis) / (100);
    }

    public static scaleBackY(yImg:number): number {
        return (yImg - this.oYAxis) / (-100);
    }
}


class ImageGenerator {

    private question: string = "";
    private answ: string = "";


    generateImage(r: string, rMin: number,  rMax: number, alfaMin: number, alfaMax: number, thMin: number, thMax: number, circleVisible: boolean, axisVisible: boolean, scaleTriangle: boolean, q: string): string {

        var showH: boolean = false;
        var showA: boolean = false;
        var showO: boolean = false;
        var showAlpha: boolean = false;
        var showR: boolean = false;
        var adjIsR: boolean = false;

        var colors = ["#00ff00","#0000ff","#ff0000"]; 
        this.shuffle(colors);
        var sideId = ["a","b","c"]; 
        this.shuffle(sideId);

        var color_black: string = "#000000";
        var color_H: string = colors[0];
        var color_O: string = colors[1];
        var color_A: string = colors[2];

        var idH = sideId[0];
        var idO = sideId[1];
        var idA = sideId[2];
      

        // r
        var rLabel: string = "";
        var rVal: number = this.getIntValue(rMin, rMax);
        if (r != null) {
            rLabel = r;
            rVal = 1.0;
        } else {
            rLabel = "" +  rVal;
        }

        var alpha: number = Math.round(this.getDoubleValueS(alfaMin, alfaMax));
        var alfaRad: number = 2 * Math.PI * alpha / 360;

        var th: number = this.getDoubleValueS(thMin, thMax);
        var thRad: number = 2 * 3.14159 * th / 360;


        switch(q) { 
            case "alpha(H,A)": {adjIsR = false; break; } 
            case "alpha(H,O)": {adjIsR = false; break; } 
            case "alpha(A,O)": {adjIsR = false; break; } 
            case "alpha(A,r,rH)": {adjIsR = false; break; } 
            case "alpha(H,rA)": {adjIsR = true; break; } 
            case "alpha(O,rH)": {adjIsR = false; break; } 
            case "alpha(O,rA)": {adjIsR = true; break; } 

            case "H(A,O)": {adjIsR = false; break; } 
            case "H(A,alpha)": {adjIsR = false; break; } 
            case "H(O,alpha)": {adjIsR = false; break; } 
            case "H(O,rA)": {adjIsR = true; break; } 
            case "H(alpha,rH)": {adjIsR = true; break; } 

            case "A(H,O)": {adjIsR = false; break; } 
            case "A(H,alpha)": {adjIsR = false; break; } 
            case "A(O,alpha)": {adjIsR = false; break; } 
            case "A(O,rH)": {adjIsR = false; break; } 
            case "A(alpha,rH)": {adjIsR = false; break; } 

            case "O(H,A)": {adjIsR = false; break; } 
            case "O(H,alpha)": {adjIsR = false; break; } 
            case "O(A,alpha)": {adjIsR = false; break; } 
            case "O(A,rH)": {adjIsR = false; break; } 
            case "O(H,rA)": {adjIsR = true; break; } 
            case "O(alpha,rH)": {adjIsR = false; break; } 
            case "O(alpha,rA)": {adjIsR = true; break; } 

            case "id(H)": {adjIsR = false; break; } 
            case "id(A)": {adjIsR = false; break; } 
            case "id(O)": {adjIsR = false; break; } 
            case "name(id(H))": {adjIsR = false; break; } 
            case "name(id(A))": {adjIsR = false; break; } 
            case "name(id(O))": {adjIsR = false; break; } 

            default: { 
                break; 
            } 
        } 


        var p0: Point = new Point(0.0, 0.0, thRad);
        var pX: Point = adjIsR ? new Point(1.0, Math.tan(alfaRad), thRad) : new Point(Math.cos(alfaRad), Math.sin(alfaRad), thRad);
        var pB: Point = adjIsR ? new Point(1.0, 0.0, thRad) : new Point(Math.cos(alfaRad), 0.0, thRad);

        var H = (rVal * Math.sqrt(Math.pow(pX.X(),2) + Math.pow(pX.Y(),2))).toFixed(2) + (r != null?rLabel:"");
        var A = (rVal * pX.X()).toFixed(2) + (r != null?rLabel:"");
        var O = (rVal * pX.Y()).toFixed(2) + (r != null?rLabel:"");

        switch(q) { 
            case "alpha(H,A)": {showH = true; showA = true; showO = false; showAlpha = false; showR = false; adjIsR = false;  this.question = "Calcule α"; this.answ = alpha.toFixed(0); break; } 
            case "alpha(H,O)": {showH = true; showA = false; showO = true; showAlpha = false; showR = false; adjIsR = false;  this.question = "Calcule α"; this.answ = alpha.toFixed(0); break; } 
            case "alpha(A,O)": {showH = false; showA = true; showO = true; showAlpha = false; showR = false; adjIsR = false;  this.question = "Calcule α"; this.answ = alpha.toFixed(0); break; } 
            case "alpha(A,r,rH)": {showH = false; showA = true; showO = false; showAlpha = false; showR = true; adjIsR = false;  this.question = "Calcule α"; this.answ = alpha.toFixed(0); break; } 
            case "alpha(H,rA)": {showH = true; showA = false; showO = false; showAlpha = false; showR = true; adjIsR = true;  this.question = "Calcule α"; this.answ = alpha.toFixed(0); break; } 
            case "alpha(O,rH)": {showH = false; showA = false; showO = true; showAlpha = false; showR = true; adjIsR = false;  this.question = "Calcule α"; this.answ = alpha.toFixed(0); break; } 
            case "alpha(O,rA)": {showH = false; showA = false; showO = true; showAlpha = false; showR = true; adjIsR = true;  this.question = "Calcule α"; this.answ = alpha.toFixed(0); break; } 

            case "H(A,O)": {showH = false; showA = true; showO = true; showAlpha = false; showR = false; adjIsR = false;  this.question = "Calcule " + idH; this.answ = H; break; } 
            case "H(A,alpha)": {showH = false; showA = true; showO = false; showAlpha = true; showR = false; adjIsR = false;  this.question = "Calcule " + idH; this.answ = H; break; } 
            case "H(O,alpha)": {showH = false; showA = false; showO = true; showAlpha = true; showR = false; adjIsR = false;  this.question = "Calcule " + idH; this.answ = H; break; } 
            case "H(O,rA)": {showH = false; showA = false; showO = true; showAlpha = false; showR = true; adjIsR = true;  this.question = "Calcule " + idH; this.answ = H; break; } 
            case "H(alpha,rH)": {showH = false; showA = false; showO = false; showAlpha = true; showR = true; adjIsR = true;  this.question = "Calcule " + idH; this.answ = H; break; } 

            case "A(H,O)": {showH = true; showA = false; showO = true; showAlpha = false; showR = false; adjIsR = false;  this.question = "Calcule " + idA; this.answ = A; break; } 
            case "A(H,alpha)": {showH = true; showA = false; showO = false; showAlpha = true; showR = false; adjIsR = false;  this.question = "Calcule " + idA; this.answ = A; break; } 
            case "A(O,alpha)": {showH = false; showA = false; showO = true; showAlpha = true; showR = false; adjIsR = false;  this.question = "Calcule " + idA; this.answ = A; break; } 
            case "A(O,rH)": {showH = false; showA = false; showO = true; showAlpha = false; showR = true; adjIsR = false;  this.question = "Calcule " + idA; this.answ = A; break; } 
            case "A(alpha,rH)": {showH = false; showA = false; showO = false; showAlpha = true; showR = true; adjIsR = false;  this.question = "Calcule " + idA; this.answ = A; break; } 

            case "O(H,A)": {showH = true; showA = true; showO = false; showAlpha = false; showR = false; adjIsR = false;  this.question = "Calcule " + idO; this.answ = O; break; } 
            case "O(H,alpha)": {showH = true; showA = false; showO = false; showAlpha = true; showR = false; adjIsR = false;  this.question = "Calcule " + idO; this.answ = O; break; } 
            case "O(A,alpha)": {showH = false; showA = true; showO = false; showAlpha = true; showR = false; adjIsR = false;  this.question = "Calcule " + idO; this.answ = O; break; } 
            case "O(A,rH)": {showH = false; showA = true; showO = false; showAlpha = false; showR = true; adjIsR = false;  this.question = "Calcule " + idO; this.answ = O; break; } 
            case "O(H,rA)": {showH = true; showA = false; showO = false; showAlpha = false; showR = true; adjIsR = true;  this.question = "Calcule " + idO; this.answ = O; break; } 
            case "O(alpha,rH)": {showH = false; showA = false; showO = false; showAlpha = true; showR = true; adjIsR = false;  this.question = "Calcule " + idO; this.answ = O; break; } 
            case "O(alpha,rA)": {showH = false; showA = false; showO = false; showAlpha = true; showR = true; adjIsR = true;  this.question = "Calcule " + idO; this.answ = O; break; } 

            case "id(H)": {showH = false; showA = false; showO = false; showAlpha = false; showR = false; adjIsR = false;  this.question = "¿Cuál es la hipotenusa?"; this.answ = idH; break; } 
            case "id(A)": {showH = false; showA = false; showO = false; showAlpha = false; showR = false; adjIsR = false;  this.question = "¿Cuál es el lado adjacente?"; this.answ = idA; break; } 
            case "id(O)": {showH = false; showA = false; showO = false; showAlpha = false; showR = false; adjIsR = false;  this.question = "¿Cuál es el lado opuesto?"; this.answ = idO; break; } 
            case "name(id(H))": {showH = false; showA = false; showO = false; showAlpha = false; showR = false; adjIsR = false;  this.question = "¿Qué lado es " + idH + "?"; this.answ = "Hipotenusa"; break; } 
            case "name(id(A))": {showH = false; showA = false; showO = false; showAlpha = false; showR = false; adjIsR = false;  this.question = "¿Qué lado es " + idA + "?"; this.answ = "Adjacente"; break; } 
            case "name(id(O))": {showH = false; showA = false; showO = false; showAlpha = false; showR = false; adjIsR = false;  this.question = "¿Qué lado es " + idO + "?"; this.answ = "Opuesto"; break; } 

            default: { 
                break; 
            } 
        } 


        var arcP: number = 1.0 / 3.0;
        var arcRad: number = arcP * Point.rCircleInImage;
        var pAlpha: Point = new Point(1.2 * arcP * Math.cos(alfaRad / 2.0), 1.2 * arcP * Math.sin(alfaRad / 2.0) / 2.5, thRad);
        var pAlpha0: Point = new Point(arcP, 0.0, thRad);
        var pAlpha1: Point = new Point(arcP * Math.cos(alfaRad), arcP * Math.sin(alfaRad), thRad);

        var arc: string = "";
        if (alpha <= 180) {
            arc = "0 0";
        } else {
            arc = "1 0";
        }

        var aX1: Point = new Point(-1.1, 0.0, thRad);
        var aX2: Point = new Point(1.1, 0.0, thRad);
        var aY1: Point = new Point(0.0, -1.1, thRad);
        var aY2: Point = new Point(0.0, 1.1, thRad);

        var pa: Point = new Point(pX.X() / 2, pX.Y() / 2, thRad);
        var pb: Point = new Point(pX.X(), pX.Y() / 2, thRad);
        var pc: Point = new Point(pX.X() / 2, p0.Y() / 2, thRad);

        var pLa: Point = new Point(
            Point.scaleBackX(pa.XImgO() - 7 - 10 * ((pX.X() * pX.Y()) < 0 ? -1.0 : 1.0) * pX.Y()),
            Point.scaleBackY(pa.YImgO() + 7 - 10 * ((pX.X() * pX.Y()) < 0 ? -1.0 : 1.0) * pX.X()),
            thRad);
        var pLb: Point = new Point(
            Point.scaleBackX(pb.XImgO() - 7 + (pX.X() > 0.0 ? 10 : -10)),
            Point.scaleBackY(pb.YImgO() + 7),
            thRad);
        var pLc: Point = new Point(
            Point.scaleBackX(pc.XImgO() - 7),
            Point.scaleBackY(pc.YImgO() + 7 + (pX.Y() >= 0.0 ? 10 : -10)),
            thRad);

        var pRVal = new Point(1.3,1.0,0.0);
        var pAlphaVal = new Point(1.3,0.8,0.0);
        var pHVal = new Point(1.3,0.6,0.0);
        var pOVal = new Point(1.3,0.4,0.0);
        var pAVal = new Point(1.3,0.2,0.0);

        return ""
                + "<svg id=\"Capa_1\" enable-background=\"new 0 0 400 250\" viewBox=\"0 0 400 250\" width=\"100%\" height=\"100%\" xmlns=\"http://www.w3.org/2000/svg\">\n"
//                + "    <g>\n"
//                + "        <title>background</title>\n"
//                + "        <rect x=\"-1\" y=\"-1\" width=\"402\" height=\"252\" id=\"canvas_background\" fill=\"none\"/>\n"
//                + "        <g id=\"canvasGrid\" display=\"none\">\n"
//                + "            <rect id=\"svg_2\" width=\"100%\" height=\"100%\" x=\"0\" y=\"0\" stroke-width=\"0\" fill=\"url(#gridpattern)\"/>\n"
//                + "        </g>\n"
//                + "    </g>\n"
                + "    <g>\n"
                + "        <title>Layer 1</title>\n"
                + "        <path d=\"M " + pAlpha0.XImg() + " " + pAlpha0.YImg() + " A 33 33 0 " + arc + " " + pAlpha1.XImg() + " " + pAlpha1.YImg() + " \"  fill=\"transparent\" stroke=\"black\" stroke-opacity=\"50%\" />" + "\n"
                + (circleVisible ? "        <circle stroke=\"#000\" fill-opacity=\"0.4\" fill=\"#FFF\" stroke-width=\"0.5\" cx=\"" + p0.XImg() + "\" cy=\"" + p0.YImg() + "\" id=\"svg_cirle\" r=\"" + Point.rCircleInImage + "\"/>\n" : "")
                + (axisVisible ? "        <line fill=\"none\" x1=\"" + aX1.XImg() + "\" y1=\"" + aX1.YImg() + "\" x2=\"" + aX2.XImg() + "\" y2=\"" + aX2.YImg() + "\" id=\"svg_y_axis\" stroke-linejoin=\"undefined\" stroke-linecap=\"undefined\" stroke=\"#000\"/>\n" : "")
                + (axisVisible ? "        <line fill=\"none\" x1=\"" + aY1.XImg() + "\" y1=\"" + aY1.YImg() + "\" x2=\"" + aY2.XImg() + "\" y2=\"" + aY2.YImg() + "\" id=\"svg_x_axis\" stroke-linejoin=\"undefined\" stroke-linecap=\"undefined\" stroke=\"#000\"/>\n" : "")
                + "        <line stroke=\"" + color_H + "\" fill=\"none\" stroke-width=\"2.0\" x1=\"" + p0.XImg() + "\" y1=\"" + p0.YImg() + "\" x2=\"" + pX.XImg() + "\" y2=\"" + pX.YImg() + "\" id=\"svg_hypothen\" stroke-linejoin=\"undefined\" stroke-linecap=\"undefined\"/>\n"
                + "        <line stroke=\"" + color_O + "\" fill=\"none\" stroke-width=\"2.0\" x1=\"" + pX.XImg() + "\" y1=\"" + pX.YImg() + "\" x2=\"" + pB.XImg() + "\" y2=\"" + pB.YImg() + "\" id=\"svg_opposite\" stroke-linejoin=\"undefined\" stroke-linecap=\"undefined\"/>\n"
                + "        <line stroke=\"" + color_A + "\" fill=\"none\" stroke-width=\"2.0\" x1=\"" + p0.XImg() + "\" y1=\"" + p0.YImg() + "\" x2=\"" + pB.XImg() + "\" y2=\"" + pB.YImg() + "\" id=\"svg_adjacent\" stroke-linejoin=\"undefined\" stroke-linecap=\"undefined\"/>\n"
                + "        <text fill=\"" + color_H + "\" stroke=\"" + color_H + "\" stroke-width=\"0\" stroke-opacity=\"null\" fill-opacity=\"null\" x=\"" + pLa.XImg() + "\" y=\"" + pLa.YImg() + "\" id=\"svg_5\" font-size=\"20\" font-family=\"Helvetica, Arial, sans-serif\" text-anchor=\"start\" xml:space=\"preserve\">" + sideId[0] + "</text>\n"
                + "        <text fill=\"" + color_O + "\" stroke=\"" + color_O + "\" stroke-width=\"0\" stroke-opacity=\"null\" fill-opacity=\"null\" x=\"" + pLb.XImg() + "\" y=\"" + pLb.YImg() + "3\" id=\"svg_5\" font-size=\"20\" font-family=\"Helvetica, Arial, sans-serif\" text-anchor=\"start\" xml:space=\"preserve\">" + sideId[1] + "</text>\n"
                + "        <text fill=\"" + color_A + "\" stroke=\"" + color_A + "\" stroke-width=\"0\" stroke-opacity=\"null\" fill-opacity=\"null\" x=\"" + pLc.XImg() + "\" y=\"" + pLc.YImg() + "\" id=\"svg_5\" font-size=\"20\" font-family=\"Helvetica, Arial, sans-serif\" text-anchor=\"start\" xml:space=\"preserve\">" + sideId[2] + "</text>\n"
//                + "        <text fill=\"" + color_black + "\" stroke=\"" + color_black + "\" stroke-width=\"0\" stroke-opacity=\"null\" fill-opacity=\"null\" x=\"" + (p0.XImg() - 15) + "\" y=\"" + aY2.YImg() + "\" id=\"svg_5\" font-size=\"14\" font-family=\"Helvetica, Arial, sans-serif\" text-anchor=\"start\" xml:space=\"preserve\">r = " + rLabel + "</text>\n"
                + "        <text fill=\"" + color_black + "\" stroke=\"" + color_black + "\" stroke-width=\"0\" stroke-opacity=\"null\" fill-opacity=\"null\" x=\"" + (pAlpha.XImg() - 3) + "\" y=\"" + (pAlpha.YImg() + 3) + "\" id=\"svg_5\" font-size=\"14\" font-family=\"Helvetica, Arial, sans-serif\" text-anchor=\"start\" xml:space=\"preserve\">" + "α" + "</text>\n"
                + (showR ? "        <text fill=\"" + color_black + "\" stroke=\"" + color_black + "\" stroke-width=\"0\" stroke-opacity=\"null\" fill-opacity=\"null\" x=\"" + pRVal.XImg() + "\" y=\"" + pRVal.YImg() + "\" id=\"svg_5\" font-size=\"14\" font-family=\"Helvetica, Arial, sans-serif\" text-anchor=\"start\" xml:space=\"preserve\">" + "r = " + rLabel + "</text>\n" : "")
                + (showAlpha ? "        <text fill=\"" + color_black + "\" stroke=\"" + color_black + "\" stroke-width=\"0\" stroke-opacity=\"null\" fill-opacity=\"null\" x=\"" + pAlphaVal.XImg() + "\" y=\"" + pAlphaVal.YImg() + "\" id=\"svg_5\" font-size=\"14\" font-family=\"Helvetica, Arial, sans-serif\" text-anchor=\"start\" xml:space=\"preserve\">" + "α = " + alpha + "º</text>\n" : "")
                + (showH ? "        <text fill=\"" + color_H + "\" stroke=\"" + color_H + "\" stroke-width=\"0\" stroke-opacity=\"null\" fill-opacity=\"null\" x=\"" + pHVal.XImg() + "\" y=\"" + pHVal.YImg() + "\" id=\"svg_5\" font-size=\"14\" font-family=\"Helvetica, Arial, sans-serif\" text-anchor=\"start\" xml:space=\"preserve\">" + idH +  " = "  + H + "</text>\n" : "")
                + (showA ? "        <text fill=\"" + color_A + "\" stroke=\"" + color_A + "\" stroke-width=\"0\" stroke-opacity=\"null\" fill-opacity=\"null\" x=\"" + pAVal.XImg() + "\" y=\"" + pAVal.YImg() + "\" id=\"svg_5\" font-size=\"14\" font-family=\"Helvetica, Arial, sans-serif\" text-anchor=\"start\" xml:space=\"preserve\">" + idA +  " = " + A + "</text>\n" : "")
                + (showO ? "        <text fill=\"" + color_O + "\" stroke=\"" + color_O + "\" stroke-width=\"0\" stroke-opacity=\"null\" fill-opacity=\"null\" x=\"" + pOVal.XImg() + "\" y=\"" + pOVal.YImg() + "\" id=\"svg_5\" font-size=\"14\" font-family=\"Helvetica, Arial, sans-serif\" text-anchor=\"start\" xml:space=\"preserve\">" + idO +  " = " + O + "</text>\n" : "")
                + "    </g>\n"
                + "</svg>";
    }

    private getIntValue(vMin: number, vMax: number): number {
        let rnd = vMin + Math.random() * (vMax - vMin);
        return Math.round(rnd);
    }

    private getDoubleValueS(vMin: number, vMax: number): number {
        return vMin + Math.random() * (vMax - vMin);
    }

    private shuffle(array: Array<string>) {
        array.sort(() => Math.random() - 0.5);
    }

    public getQuestion() {
        return this.question;
    }

    public getAnswer() {
        return this.answ;
    }

}




