import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import Amplify from 'aws-amplify';
import amplify from './aws-exports';

// Manually adding Cognito parameters to other parameters in amplify 
amplify["aws_cognito_identity_pool_id"] = "us-east-1:929c8c15-ce04-4a95-9e15-e9c25b799663";
amplify["aws_cognito_region"] = "us-east-1";
amplify["aws_user_pools_id"] = "us-east-1_O06wY2FNP";
amplify["aws_user_pools_web_client_id"] = "58hf5f3ia0p7v7teh1jtceagn9";

Amplify.configure(amplify);


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
