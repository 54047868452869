import { Injectable } from "@angular/core";
import { AmplifyService } from "aws-amplify-angular";
import { Router } from "@angular/router";
import { APIService } from '../API.service';
import { AreasService } from "../areas/areas.service";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  signedIn: boolean;
  user: any;
  greeting: string;
  uiid: string;
  public justChanged: boolean;

  constructor(
    private amplifyService: AmplifyService,
    private router: Router,
    private areasService: AreasService,
    public apiService: APIService,
    ) {
    this.amplifyService.authStateChange$.subscribe(authState => {
      this.signedIn = authState.state === "signedIn";
      if (!authState.user) {
        this.user = null;
        console.log("***** Authentication A ******");
        router.navigateByUrl("/auth");
      } else {
        this.user = authState.user;
        this.greeting = "Hello " + this.user.username;
        console.log("***** Authentication B ******");

        let userName = this.user.username;
        let clientId = this.user.pool.clientId;
        let key = 'CognitoIdentityServiceProvider.' + clientId + '.' + userName + '.userData';
        let attr = JSON.parse( this.user.pool.storage[key] ).UserAttributes;

        for(let a in attr ) {
          if(attr[a].Name === 'sub') {
            this.uiid = attr[a].Value;
            console.log('amplifyService.pubsub: ' + this.uiid );
          }
        }

        this.apiService.CreateUsuario({
          usuarioId: this.uiid,
          username: this.user.username,
          registered: true,
          timeZone: 'America/Bogota'
        })

        console.log('¿¿¿¿¿¿¿¿¿¿¿   this.justChanged = true; ???????????????????');
        this.justChanged = true;
        console.log('this.justChanged C: ' + this.justChanged);

        if (authState.state != "confirmSignUp") {
          console.log('llegó por este punto para retos');
          // console.log(JSON.stringify(authState));
          this.areasService.readAreas();
          router.navigateByUrl('/retos');
          // router.navigateByUrl('/retos-list');
          // router.navigateByUrl('/auth');
        }
      }
    });
  }

  logout() {
    console.log("Logout from authService");
    this.amplifyService
      .auth()
      .signOut()
      .then(data => console.log("data: " + data))
      .catch(err => console.log("err: " + err));
  }
}
