// https://www.typescriptlang.org/docs/handbook/modules.html

import { EvaluInterface } from "./evalu-interface.model";
import { ResponseInterface } from "./response-interface.model";
import { RetoInterface } from "./reto-interface.model";

export class RetoGeneral implements RetoInterface {
  id: string;
  name: string;
  description: string;
  private evals: EvaluInterface[];
  progress: number;
  maxQtyEval: number;
  image: string;
  qtyEvals: number;
  totEvals: number;
  responses: ResponseInterface[];

  private currentEval: EvaluInterface;
  private totCnt: number;

  constructor(
    _id: string,
    _name: string,
    _description: string,
    _progress: number,
    _maxQtyEval: number,
    _image: string,
    _qtyEvals: number,
    _totEvals: number,
    _qas: EvaluInterface[]
  ) {
    this.id = _id;
    this.name = _name;
    this.description = _description;
    this.progress = _progress;
    this.maxQtyEval = _maxQtyEval;
    this.image = _image;
    this.qtyEvals = _qtyEvals;
    this.totEvals = _totEvals;
    this.evals = _qas;
    this.totCnt = this.getLeftCountEval('Constructor');
    this.responses = [];
  }

  setEvals(_evals: EvaluInterface[]) {
    this.evals = _evals;
    this.totCnt = this.evals.length;
  }

  isEvalsSet(): boolean {
    // console.log('=========================================')   ;
    // console.log(this.evals);
    return !(this.evals === null);
  }

  public getTotalCountEval() {
    return this.totCnt;
  }

  public getLeftCountEval(o: string) {
    // console.log('Lllegó desde: ' + o);
    // console.log('>>>>>>>><<<<>>>>>>>>>>   this.evals: ' + this.evals);
    if(this.evals === null) {
      // console.log('this.evals === null');
      return this.qtyEvals;

    } else  {
      // console.log('this.evals IS NOT null');
      this.qtyEvals = this.evals.length;
      return this.evals.length;
    }
  }

  public getCurrentEval() {
    return this.currentEval;
  }

  public getNextEval() {
    // console.log('... A antes de this.getLeftCountEval()');
    var l = this.getRandomInt(this.getLeftCountEval('A'));
    var id =
      typeof this.currentEval === "undefined" ? -1 : this.currentEval.getId();

    // console.log("l: " + l);
    // console.log('* * * this.currentEval * * *: ' + id);

    this.currentEval = this.evals[l];

    if (this.currentEval.getId() == id) {
      // console.log('* * * this.currentEval.getId() * * *: ' + this.currentEval.getId());
      l++

      console.log('... B antes de this.getLeftCountEval()');
      l = l >= this.getLeftCountEval('B') ? 0 : l;
      this.currentEval = this.evals[l];
    }

    return this.currentEval;
  }

  public removeCurrentEval() {
    const index = this.evals.indexOf(this.currentEval, 0);
    if (index > -1) {
      this.evals.splice(index, 1);
    }
  }

  public empty() {
    console.log('... C antes de this.getLeftCountEval()');
    return this.getLeftCountEval('C') == 0;
  }

  public getRetoScore(): string {
    let myMap = new Map();

    for (let r of this.responses) {
      var id = r.evalu.getId();

      if (!myMap.has(id)) {
        myMap.set(id, 0);
      }
      myMap.set(id, myMap.get(id) + 1);
    }

    var sum = 0;
    for (let value of myMap.values()) {
       if (value == 1) {
        sum++;
      }
    }

    if(myMap.size == 0) {
        return '¡Día libre!';
    }

    var score = (5 * sum) / myMap.size;
    return score.toFixed(1) + " / 5.0 " + (score > 4.5 ? "Súper!" : (score > 4 ? "Bien!" : (score > 3 ? "Vamos!" : (score > 2 ? "Mañana será otro día" : ""))));
  }

  public addResponse(response: ResponseInterface) {
    this.responses.push(response);
  }

  private getRandomInt(max: number) {
    return Math.floor(Math.random() * Math.floor(max));
  }
}
