import { EvaluInterface } from './evalu-interface.model';
import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export class QATranslate implements EvaluInterface {
  private id: string;
  private q: string;
  private a: string;
  private rt: number;

  @JsonProperty()
  private w1: string;
  @JsonProperty()
  private w2: string;

  constructor(_id: string, _w1: string, _w2: string, _rt: number) {
    this.id = _id;
    this.w1 = _w1;
    this.w2 = _w2;
    this.rt = _rt;
    console.log(this.id);
    console.log(this.w1);
    console.log(this.w2);
    console.log(this.rt);
  }

  getId(): string {
    return this.id;
  }

  getQNew() {
    this.q = this.w1;
    this.a = this.w2;
    return this.q;
  }

  getQ(): string {
    return this.q;
  }

  getQShort(): string {
    return this.q;
  }

  getA(): string {
    return this.a;
  }

  gradeAnswer(answer: string): number {
    console.log(answer);
    console.log(this.a.length);
    var g = 1 - this.levenshteinDistance(this.a, answer) / this.a.length;
    return g < 0 ? 0 : g;
  }

  private getRandomInt(max: number) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  getTimeToRespondMs() {
    return this.rt;
  }

  getEvalDisplay() {
    return "translateDisplay";
  }

  levenshteinDistance(a: string, b: string) {
    if (a.length == 0) return b.length;
    if (b.length == 0) return a.length;

    var matrix = [];

    // increment along the first column of each row
    var i: number;
    for (i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    // increment each column in the first row
    var j: number;
    for (j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (i = 1; i <= b.length; i++) {
      for (j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) == a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            Math.min(
              matrix[i][j - 1] + 1, // insertion
              matrix[i - 1][j] + 1
            )
          ); // deletion
        }
      }
    }

    return matrix[b.length][a.length];
  }
}
