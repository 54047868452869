import { EvaluInterface } from "./evalu-interface.model";

export class EvaluMultipleChoice implements EvaluInterface {
  private id: string;
  private options: string;
  private a: string;
  private rt: number;

  constructor(_id: string, _options: string, _rt: number) {
    this.id = _id;
    this.options = _options;
    this.options = this.shuffle(this.options);
    this.rt = _rt;
    this.a = this.theA(this.options);
  }

  private theA(options): string {
    for (let o in options) {
      if (options[o].ok == 1) {
        return options[o].o;
      }
    }
    return "";
  }

  getId(): string {
    return this.id;
  }

  getQNew() {
    return this.getQ();
  }

  getQ(): string {
    console.log(
      "JSON.stringify(this.options):: " + JSON.stringify(this.options)
    );
    return this.options;
  }

  getQShort(): string {
    return JSON.stringify(this.options);
  }

  getA(): string {
    return this.a;
  }

  gradeAnswer(answer: string): number {
    return this.a == answer ? 1.0 : 0.0;
  }

  getTimeToRespondMs() {
    return this.rt;
  }

  getEvalDisplay() {
    return "multipleChoiceDisplay";
  }

  // https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
  private shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
}
