import { EvaluInterface } from "./evalu-interface.model";

export class EvaluMinusFlash implements EvaluInterface {
  private id: string;
  private q: string;
  private a: string;
  private rt: number;

  constructor(_id: string, _v1: number, _v2: number, _rt: number) {
    this.id = _id;
    this.q = _v1 + " - " + _v2;
    this.a = _v1 - _v2 + "";
    this.rt = _rt;
  }

  getId(): string {
    return this.id;
  }

  getQNew() {
    return this.getQ();
  }

  getQShort() {
    return this.getQ();
  }

  getQ(): string {
    return this.q;
  }

  getA(): string {
    return this.a;
  }

  gradeAnswer(answer: string): number {
    return this.a == answer ? 1.0 : 0.0;
  }

  getTimeToRespondMs() {
    return this.rt;
  }

  getEvalDisplay() {
    return "matheDisplay";
  }
}
