import { EvaluInterface } from "./evalu-interface.model";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

export class EvaluMusicNotes implements EvaluInterface {
  private id: string;
  private rt: number;
  private numPuntos: number;

  private question;
  private asw: string;


  private sanitizer: DomSanitizer;
  svg: SafeHtml;

  constructor(
    _id: string,
    _rt: number,
    _numPuntos: number
  ) {
    this.id = _id;
    this.rt = _rt;

    this.numPuntos = _numPuntos;
  }

  getId(): string {
    return this.id;
  }

  getQNew() {
    const c = new Canvas();
    var theSVGimg = c.getImg(
        this.numPuntos
      )
    this.question = {
      img: theSVGimg,
    };
    this.asw = '' + this.numPuntos;
    return JSON.stringify(this.question);
  }

  getQ(): string {
    return this.question;
  }

  getQShort(): string {
    return 'img with ' + this.numPuntos + ' pts.';
  }

  getA(): string {
    return this.asw;
  }

  gradeAnswer(answer: string): number {
    console.log(answer.toLowerCase());
    console.log(this.asw.length);
    return answer == this.asw ? 1 : 0;
  }

  getTimeToRespondMs() {
    return this.rt;
  }

  getEvalDisplay() {
    return "musicNotesDisplay";
  }
}

/*

  Desde aquí comienaza códibo para crear gráfico dinámico

  Se prueba desde aquí hasta el final en: https://www.typescriptlang.org/v2/en/play

*/




class Point {
  private x: number;
  private y: number;

  constructor(_x: number, _y: number) {
    this.x = _x;
    this.y = _y;
  }

  X(): number {
    return this.x;
  }


  Y(): number {
    return this.y;
  }
}

class Canvas {

  cXMax: number = 300;
  cYMax: number = 150;

  r: number = 8;

  xMin: number = 0 + this.r;
  xMax: number = this.cXMax - this.r;
  yMin: number = 0 + this.r;
  yMax: number = this.cYMax - this.r;


  getImg(numPoints: number) {

    var points: Point[] = new Array<Point>();

    for (var k = 0; k < numPoints; k++) {
      points.push(new Point(this.xMin + Math.random() * (this.xMax - this.xMin), this.yMin + Math.random() * (this.yMax - this.yMin)));
    }

    var svg: string;
    svg = '<?xml version="1.0" standalone="no"?>'
    svg += '<svg width="' + this.cXMax + '" height="' + this.cYMax + '" version="1.1" xmlns="http://www.w3.org/2000/svg">'

    for (var k = 0; k < numPoints; k++) {
      //console.log('points[' + k + ']: ', points[k])
      svg +=  '<circle cx="' + points[k].X() + '" cy="' + points[k].Y() + '" r="' + this.r + '" stroke="red" fill="green" stroke-width="2"/>'
    }
    svg +=  '</svg>'

    return svg;
  }

}



// var c = new Canvas();
// console.log(c.getImg(2))
