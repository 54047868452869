import { Component } from '@angular/core';
import { Media, MediaObject } from '@ionic-native/media/ngx';


import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './auth/auth.service';

import { AreasService } from './areas/areas.service';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  mediaFile: MediaObject;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private areasService: AreasService,
    private media: Media
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      // this.areasService.readAreas();
    });
  }

  onLogout() {
    this.authService.logout();
  }

  sayHallo() {
    console.log('Say hallo!');
    this.playAudio();
  }

  playAudio() {
    console.log('en playAudio()');

    this.mediaFile = this.media.create('http://tantepau.com/audio/8x7.mp3');

    // to listen to plugin events:

    this.mediaFile.onStatusUpdate.subscribe((status) => console.log(status)); // fires when file status changes

    this.mediaFile.onSuccess.subscribe(() =>
      console.log('Action is successful')
    );

    this.mediaFile.onError.subscribe((error) => console.log('Error!', error));

    // play the file
    this.mediaFile.play();

  }
}
