import { EvaluInterface } from "./evalu-interface.model";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export class EvaluTriangle implements EvaluInterface {
  private id: string;
  private img: string;
  private asw: string;
  private rt: number;
  
  private sanitizer: DomSanitizer;
  svg:SafeHtml;

  constructor(_id: string, _img: string, _asw: string, _rt: number) {
    this.id = _id;
    this.img = _img;
    this.asw = _asw ;
    this.rt = _rt ;
  }

  getId(): string {
    return this.id;
  }

  getQNew() {
    const ig = new ImageGenerator();
    this.img= ig.generateImage("A", 0, 0, 0, 360, 0, 0, false, true, true, true);
    return this.img;
  }

  getQ(): string {
    return this.img;
  }
  
  getQShort(): string {
    return 'Img:'+this.asw;
  }

  getA(): string {
    return this.asw;
  }

  gradeAnswer(answer: string): number {
    console.log(answer);
    console.log(this.asw.length);
    var g = 1 - this.levenshteinDistance(this.asw, answer) / this.asw.length;
    return g < 0 ? 0 : g;
  }

  getTimeToRespondMs() {
    return this.rt;
  }

  getEvalDisplay() {
    return "triangleDisplay";
  }
  
  levenshteinDistance(a: string, b: string) {
    if (a.length == 0) return b.length;
    if (b.length == 0) return a.length;

    var matrix = [];

    // increment along the first column of each row
    var i: number;
    for (i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    // increment each column in the first row
    var j: number;
    for (j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (i = 1; i <= b.length; i++) {
      for (j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) == a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            Math.min(
              matrix[i][j - 1] + 1, // insertion
              matrix[i - 1][j] + 1
            )
          ); // deletion
        }
      }
    }

    return matrix[b.length][a.length];
  }  
}

interface DiagramSettings {
    oXAxis: number;
    oYAxis: number;
    rCircleInImage: number;
}


class Point implements DiagramSettings {

    oXAxis: number = 200.0;
    oYAxis: number = 125.0;
    rCircleInImage: number = 100.0;

    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    X(): number {
        return this.x;
    }
    
    Y(): number {
        return this.y;
    }

    XImg(): number {
        return this.transformToImg(this.x, this.oXAxis, 100);
    }
    
    YImg(): number {
        return this.transformToImg(this.y, this.oYAxis, -100);
    }

    private transformToImg(v: number, oAxis: number, scale: number): number {
        return oAxis + scale * v;
    }
}


class ImageGenerator implements DiagramSettings{

    oXAxis: number = 200.0;
    oYAxis: number = 125.0;
    rCircleInImage: number = 100.0;

    generateImage(r: string, rMin: number,  rMax: number, alfaMin: number, alfaMax: number, gamaMin: number, gamaMax: number, adjIsOne: boolean,  circleVisible: boolean, axisVisible: boolean, scaleTriangle: boolean): string {

         var color_black: string = "#000000";
         var color_blue: string = "#007fff";
         var color_red: string = "#ff0000";
         // var color_black: string = "#000000";
         // var color_blue: string = "#000000";
         // var color_red: string = "#000000";


        // r
        var rS: string = "";
        if (r != null) {
            rS = r;
        } else {
            rS = this.getIntValueStr(rMin, rMax);
        }

        var alfa: number = this.getDoubleValueS(alfaMin, alfaMax);
        var alfaRad: number = 2 * 3.14159 * alfa / 360;

        var p0: Point = new Point(0.0, 0.0);
        var pX: Point = adjIsOne ? new Point(1.0, Math.tan(alfaRad)) : new Point(Math.cos(alfaRad), Math.sin(alfaRad));
        var pB: Point = adjIsOne ? new Point(1.0, 0.0) : new Point(Math.cos(alfaRad), 0.0);

        var arcP: number = 1.0 / 3.0;
        var arcRad: number = arcP * this.rCircleInImage;
        var pAlpha: Point = new Point(1.2 * arcP * Math.cos(alfaRad / 2.0), 1.2 * arcP * Math.sin(alfaRad / 2.0) / 2.5);
        var pAlpha0: Point = new Point(arcP, 0.0);
        var pAlpha1: Point = new Point(arcP * Math.cos(alfaRad), arcP * Math.sin(alfaRad));

        var arc: string = "";
        if (alfa <= 180) {
            arc = "0 0";
        } else {
            arc = "1 0";
        }

        var aX1: Point = new Point(-1.1, 0.0);
        var aX2: Point = new Point(1.1, 0.0);
        var aY1: Point = new Point(0.0, -1.1);
        var aY2: Point = new Point(0.0, 1.1);

        var pa: Point = new Point(pX.X() / 2, pX.Y() / 2);
        var pb: Point = new Point(pX.X(), pX.Y() / 2);
        var pc: Point = new Point(pX.X() / 2, p0.Y() / 2);

        var aXImg: number = pa.XImg() - 7 - 10 * ((pX.X() * pX.Y()) < 0 ? -1.0 : 1.0) * pX.Y();
        var aYImg: number = pa.YImg() + 7 - 10 * ((pX.X() * pX.Y()) < 0 ? -1.0 : 1.0) * pX.X();
        var bXImg: number = pb.XImg() - 7 + (pX.X() > 0.0 ? 10 : -10);
        var bYImg: number = pb.YImg() + 7;
        var cXImg: number = pc.XImg() - 7;
        var cYImg: number = pc.YImg() + 7 + (pX.Y() >= 0.0 ? 10 : -10);

        return ""
                + "<svg id=\"Capa_1\" enable-background=\"new 0 0 400 250\" viewBox=\"0 0 400 250\" width=\"100%\" height=\"100%\" xmlns=\"http://www.w3.org/2000/svg\">\n"
//                + "    <g>\n"
//                + "        <title>background</title>\n"
//                + "        <rect x=\"-1\" y=\"-1\" width=\"402\" height=\"252\" id=\"canvas_background\" fill=\"none\"/>\n"
//                + "        <g id=\"canvasGrid\" display=\"none\">\n"
//                + "            <rect id=\"svg_2\" width=\"100%\" height=\"100%\" x=\"0\" y=\"0\" stroke-width=\"0\" fill=\"url(#gridpattern)\"/>\n"
//                + "        </g>\n"
//                + "    </g>\n"
                + "    <g>\n"
                + "        <title>Layer 1</title>\n"
                + "        <path d=\"M " + pAlpha0.XImg() + " " + pAlpha0.YImg() + " A 33 33 0 " + arc + " " + pAlpha1.XImg() + " " + pAlpha1.YImg() + " \"  fill=\"transparent\" stroke=\"black\" stroke-opacity=\"50%\" />" + "\n"
                + "        <circle stroke=\"#000\" fill-opacity=\"0.4\" fill=\"#FFF\" stroke-width=\"0.5\" cx=\"" + p0.XImg() + "\" cy=\"" + p0.YImg() + "\" id=\"svg_cirle\" r=\"" + this.rCircleInImage + "\"/>\n"
                + "        <line fill=\"none\" x1=\"" + aX1.XImg() + "\" y1=\"" + aX1.YImg() + "\" x2=\"" + aX2.XImg() + "\" y2=\"" + aX2.YImg() + "\" id=\"svg_y_axis\" stroke-linejoin=\"undefined\" stroke-linecap=\"undefined\" stroke=\"#000\"/>\n"
                + "        <line fill=\"none\" x1=\"" + aY1.XImg() + "\" y1=\"" + aY1.YImg() + "\" x2=\"" + aY2.XImg() + "\" y2=\"" + aY2.YImg() + "\" id=\"svg_x_axis\" stroke-linejoin=\"undefined\" stroke-linecap=\"undefined\" stroke=\"#000\"/>\n"
                + "        <line stroke=\"" + color_black + "\" fill=\"none\" stroke-width=\"2.0\" x1=\"" + p0.XImg() + "\" y1=\"" + p0.YImg() + "\" x2=\"" + pX.XImg() + "\" y2=\"" + pX.YImg() + "\" id=\"svg_hypothen\" stroke-linejoin=\"undefined\" stroke-linecap=\"undefined\"/>\n"
                + "        <line stroke=\"" + color_blue + "\" fill=\"none\" stroke-width=\"2.0\" x1=\"" + pX.XImg() + "\" y1=\"" + pX.YImg() + "\" x2=\"" + pB.XImg() + "\" y2=\"" + pB.YImg() + "\" id=\"svg_opposite\" stroke-linejoin=\"undefined\" stroke-linecap=\"undefined\"/>\n"
                + "        <line stroke=\"" + color_red + "\" fill=\"none\" stroke-width=\"2.0\" x1=\"" + p0.XImg() + "\" y1=\"" + p0.YImg() + "\" x2=\"" + pB.XImg() + "\" y2=\"" + pB.YImg() + "\" id=\"svg_adjacent\" stroke-linejoin=\"undefined\" stroke-linecap=\"undefined\"/>\n"
                + "        <text fill=\"" + color_black + "\" stroke=\"" + color_black + "\" stroke-width=\"0\" stroke-opacity=\"null\" fill-opacity=\"null\" x=\"" + aXImg + "\" y=\"" + aYImg + "\" id=\"svg_5\" font-size=\"20\" font-family=\"Helvetica, Arial, sans-serif\" text-anchor=\"start\" xml:space=\"preserve\">a</text>\n"
                + "        <text fill=\"" + color_blue + "\" stroke=\"" + color_blue + "\" stroke-width=\"0\" stroke-opacity=\"null\" fill-opacity=\"null\" x=\"" + bXImg + "\" y=\"" + bYImg + "3\" id=\"svg_5\" font-size=\"20\" font-family=\"Helvetica, Arial, sans-serif\" text-anchor=\"start\" xml:space=\"preserve\">b</text>\n"
                + "        <text fill=\"" + color_red + "\" stroke=\"" + color_red + "\" stroke-width=\"0\" stroke-opacity=\"null\" fill-opacity=\"null\" x=\"" + cXImg + "\" y=\"" + cYImg + "\" id=\"svg_5\" font-size=\"20\" font-family=\"Helvetica, Arial, sans-serif\" text-anchor=\"start\" xml:space=\"preserve\">c</text>\n"
                + "        <text fill=\"" + color_black + "\" stroke=\"" + color_black + "\" stroke-width=\"0\" stroke-opacity=\"null\" fill-opacity=\"null\" x=\"" + (p0.XImg() - 15) + "\" y=\"" + aY2.YImg() + "\" id=\"svg_5\" font-size=\"14\" font-family=\"Helvetica, Arial, sans-serif\" text-anchor=\"start\" xml:space=\"preserve\">r = " + rS + "</text>\n"
                + "        <text fill=\"" + color_black + "\" stroke=\"" + color_black + "\" stroke-width=\"0\" stroke-opacity=\"null\" fill-opacity=\"null\" x=\"" + (pAlpha.XImg() - 3) + "\" y=\"" + (pAlpha.YImg() + 3) + "\" id=\"svg_5\" font-size=\"14\" font-family=\"Helvetica, Arial, sans-serif\" text-anchor=\"start\" xml:space=\"preserve\">" + "α" + "</text>\n"
                + "    </g>\n"
                + "</svg>";
    }

    private getIntValueStr(vMin: number, vMax: number): string {
        let rnd = vMin + Math.random() * (vMax - vMin);
        return "" + Math.round(rnd);
    }

    private getDoubleValueS(vMin: number, vMax: number): number {
        return vMin + Math.random() * (vMax - vMin);
    }
}
