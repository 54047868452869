import { Injectable } from '@angular/core';

import { RetoInterface } from './reto/reto-interface.model';
import { RetoGeneral } from './reto/reto-general.model';

import { QAInterface } from './reto/qa-interface.model';
import { QASuma } from './reto/qa-suma.model';
import { QAResta } from './reto/qa-resta.model';
import { QAMultiplica } from './reto/qa-multiplica.model';
import { QAMultiInv } from './reto/qa-multiinv.model';
import { QATranslate } from './reto/qa-translate.model';
import { EvaluMultipleChoice } from './reto/evalu-multiple-choice.model';
import { EvaluImage } from './reto/evalu-image.model';
import { EvaluTrigonometry } from './reto/evalu-trigonometry.model';
import { EvaluTriangle } from './reto/evalu-triangle.model';
import { EvaluDivision } from './reto/evalu-division.model';
import { EvaluPuntos } from './reto/evalu-puntos.model';
import { EvaluPlusFlash } from './reto/evalu-plus-flash.model';
import { EvaluMinusFlash } from './reto/evalu-minus-flash.model';
import { EvaluMusicNotes } from './reto/evalu-music-notes.model';

import { AuthService } from '../auth/auth.service';

import { AreasService } from '../areas/areas.service';

import { APIService } from '../API.service';

import { BehaviorSubject } from 'rxjs';
// import { API } from "aws-amplify";


// Import functions from library
//
// En la raiz del proyecto:
//    npm install typescript-json-serializer --save
//
// Tomado de: https://www.npmjs.com/package/typescript-json-serializer

// import {
//   JsonProperty,
//   Serializable,
//   deserialize,
//   serialize
// } from "typescript-json-serializer";

@Injectable({
  providedIn: 'root'
})
export class RetosService {

  // public retosEnrolled: RetoInterface[] = [];
  // public retosAvailable: RetoInterface[] = [];


  private retosEnrolledObservableBS: BehaviorSubject<RetoInterface[]> =
    new BehaviorSubject<RetoInterface[]>([]);

  private retosAvailableObservableBS: BehaviorSubject<RetoInterface[]> =
    new BehaviorSubject<RetoInterface[]>([]);

  get retosEnrolledObservable() {
    return this.retosEnrolledObservableBS.asObservable();
  }

  set retosEnrolledObservableDate(areas: RetoInterface[] ) {
    this.retosEnrolledObservableBS.next(areas);
  }

  get retosAvailableObservable() {
    return this.retosAvailableObservableBS.asObservable();
  }

  set retosAvailableObservableDate(areas: RetoInterface[] ) {
    this.retosAvailableObservableBS.next(areas);
  }




  private retosAll: RetoInterface[] = [];
  private userRetos =  [];

  private _retos: RetoInterface[];
  private _loading: boolean;

  constructor(
    public authService: AuthService,
    public apiService: APIService,
    public areasService: AreasService
    ) {
    console.log('retos.service.ts: RetosService: CONSTRUCTOR -> IN');
    this.readRetos();
    this._loading = false;
    console.log('retos.service.ts: RetosService: CONSTRUCTOR -> OUT');
  }


  readRetos() {

    console.log('ENTRA A readRetos() .......');
    console.log('this.areasService.areasObservable .......' + this.areasService.areasObservable);

    this.areasService.areasObservable.subscribe(
      x => {
        console.log('YA PASÓ POR AREAS.......');
        console.log('x.length ......' + x.length);

        if(x.length > 0) {

          const la = this.apiService.ListRetos();
          console.log('en retosAll:');
          la.then(data => {
            console.log('Aquí están todos los retos leidos');
            console.log(data);
            this.retosAll = data.items.map(item => new RetoGeneral(
                    item.retoId,
                    item.retoName,
                    item.retoDescription,
                    0.0, // the value will be updated when readUserRetos()
                    20, // item.max_qty_eval,
                    this.areasService.getImageByAreaId(item.areaId).areaImage, // item.area_image,
                    0, // the value will be updated when readUserRetos()
                    0,
                    null// this.createEval(item.evaluations)
                  ));;

            // for (let v of this.retosAll) {
            //     console.log('v.retoId: ' + v.retoId);
            //     v.areaImage = this.areasService.getImageByAreaId(v.areaId).areaImage;
            // }
            this.readUsuarioRetos();
          });
        }
      }
    );
  }


  getEvaluations(retoId: string) {
    console.log('getEvaluations(retoId: string): ');
    console.log('this.authService.uiid: ' + this.authService.uiid);
    console.log('retoId: ' + retoId);

    return this.apiService.ListEvaluationsByUsuarioAndReto(retoId, this.authService.uiid ).then(data => {
        console.log('Dentro del callback: ');
        console.log(data);
        return this.createEval(data.items);
    });
  }

  readUsuarioRetos() {
    console.log('... en readUsuarioRetos ...');
    const la = this.apiService.ListUsuarioRetosByUsuario(this.authService.uiid);
    console.log('en userRetos: ' + this.authService.uiid);
    console.log('>>>>  la  <<<<<' + la);
    return la.then(data => {
      console.log('data dentro de readUserRetos: ');
      console.log(data);
      this.userRetos = data.items;

      for (let v of this.userRetos) {
        console.log('userRetos: v.retoId: ' + v.retoId + ' >> ' + v.numEval + ' >> v.totEval >> ' + v.totEval);
      }

      this.retosAvailableObservableDate = this.retosAll.filter(r => !this.isRetoInUserRetos(r.id));
      this.retosEnrolledObservableDate = this.retosAll.filter(r => this.isRetoInUserRetos(r.id)).map(
        r => {
          r.progress = this.getScoreFromRetoInUserRetos(r.id);
          r.qtyEvals = this.getQtyEvalFromRetoInUserRetos(r.id);
          r.totEvals = this.getTotEvalFromRetoInUserRetos(r.id);
          return r;
        });

    })
    .catch(
      x => {console.log('Juemadre !!!' + JSON.stringify(x));}
    );
  }

  isRetoInUserRetos(id: String): boolean {
    for (let v of this.userRetos) {
      if(id == v.retoId) {
        return true;
      }
    }
    return false
  }

  getScoreFromRetoInUserRetos(id: String): number {
    for (let v of this.userRetos) {
      if(id == v.retoId) {
        return v.progress;
      }
    }
    return -1.0
  }

  getQtyEvalFromRetoInUserRetos(id: String): number {
    for (let v of this.userRetos) {
      if(id == v.retoId) {
        return v.numEval;
      }
    }
    return -1.0
  }

  getTotEvalFromRetoInUserRetos(id: String): number {
    for (let v of this.userRetos) {
      if(id == v.retoId) {
        return v.totEval;
      }
    }
    return -1.0
  }


  // uploadRetos() {
  //   let promise = new Promise((resolve, reject) => {
  //     this._loading = true;
  //     console.log("********** before DELEY ****************" + new Date());

  //     // var path = "/test";
  //     var path = "/reto/list";
  //     console.log("uploadRetos: M1");
  //     let apiName = "cloud9-klugkidapi";
  //     // let apiName = 'cloud9-carrosProApp'
  //     let myInit = {
  //       headers: {
  //         "x-api-key": "rxcQp7PiJRaeqwASIf74j3c8ZavmwldT6NU75Gsf",
  //         "Content-Type": "application/json"
  //       },
  //       body: {
  //         usuario: this.authService.user.username
  //       }
  //     };
  //     console.log(apiName);
  //     console.log(path);
  //     console.log(myInit);
  //     API.post(apiName, path, myInit)
  //       .then(response => {
  //         // Add your code here
  //         console.log("*** uploadRetos: TODO OK ***");
  //         console.log(response);
  //         console.log("response: : :" + response);
  //         this._retos = response.retos.map(item => {
  //           return new RetoGeneral(
  //             item.reto_id,
  //             item.reto_name,
  //             item.reto_description,
  //             item.progress,
  //             item.max_qty_eval,
  //             item.area_image,
  //             item.q,
  //             this.createEval(item.evaluations)
  //           );
  //         });
  //       })
  //       .catch(error => {
  //         console.log("*** uploadRetos: FALLO ***");
  //         console.log(error);
  //         console.log("error: : :" + error);
  //       });

  //     console.log("********** during DELEY ****************" + new Date());
  //     resolve();
  //     this._loading = false;

  //     console.log("********* after DELEY ****************" + new Date());
  //   });
  //   return promise;
  // }

  createEval(evaluations): QAInterface[] {
    const evals = evaluations.map(evalSpec => {
      // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> evalSpec.evaluationMsResponseTimeGoal: ' + JSON.stringify(evalSpec));
      switch (evalSpec.evaluationQuestionClassName) {
        case 'QASuma': {
          return new QASuma(
            evalSpec.evaluationId,
            JSON.parse(evalSpec.evaluationQuestionParameters).v1,
            JSON.parse(evalSpec.evaluationQuestionParameters).v2,
            evalSpec.evaluationMsResponseTimeGoal
          );
        }
        case 'QAResta': {
          return new QAResta(
            evalSpec.evaluationId,
            JSON.parse(evalSpec.evaluationQuestionParameters).v1,
            JSON.parse(evalSpec.evaluationQuestionParameters).v2,
            evalSpec.evaluationMsResponseTimeGoal
          );
        }
        case 'QAMultiplica': {
          return new QAMultiplica(
            evalSpec.evaluationId,
            JSON.parse(evalSpec.evaluationQuestionParameters).v1,
            JSON.parse(evalSpec.evaluationQuestionParameters).v2,
            evalSpec.evaluationMsResponseTimeGoal
          );
        }
        case 'QAMultiInv': {
          return new QAMultiInv(
            evalSpec.evaluationId,
            JSON.parse(evalSpec.evaluationQuestionParameters).v1,
            JSON.parse(evalSpec.evaluationQuestionParameters).v2,
            evalSpec.evaluationMsResponseTimeGoal
          );
        }
        case 'QATranslate': {
          return new QATranslate(
            evalSpec.evaluationId,
            JSON.parse(evalSpec.evaluationQuestionParameters).w1,
            JSON.parse(evalSpec.evaluationQuestionParameters).w2,
            evalSpec.evaluationMsResponseTimeGoal
          );
        }
        case 'EvaluMultipleChoice': {
          return new EvaluMultipleChoice(
            evalSpec.evaluationId,
            JSON.parse(evalSpec.evaluationQuestionParameters).options,
            evalSpec.evaluationMsResponseTimeGoal
          );
        }
        case 'EvaluImage': {
          return new EvaluImage(
            evalSpec.evaluationId,
            JSON.parse(evalSpec.evaluationQuestionParameters).img,
            JSON.parse(evalSpec.evaluationQuestionParameters).asw,
            evalSpec.evaluationMsResponseTimeGoal
          );
        }
        case 'EvaluTrigonometry': {
          return new EvaluTrigonometry(
            evalSpec.evaluationId,
            evalSpec.evaluationMsResponseTimeGoal,
            JSON.parse(evalSpec.evaluationQuestionParameters).r == 'null' ? null : JSON.parse(evalSpec.evaluationQuestionParameters).r,
            JSON.parse(evalSpec.evaluationQuestionParameters).rMin,
            JSON.parse(evalSpec.evaluationQuestionParameters).rMax,
            JSON.parse(evalSpec.evaluationQuestionParameters).alfaMin,
            JSON.parse(evalSpec.evaluationQuestionParameters).alfaMax,
            JSON.parse(evalSpec.evaluationQuestionParameters).thMin,
            JSON.parse(evalSpec.evaluationQuestionParameters).thMax,
            JSON.parse(evalSpec.evaluationQuestionParameters).circleVisible == 'true',
            JSON.parse(evalSpec.evaluationQuestionParameters).axisVisible == 'true',
            JSON.parse(evalSpec.evaluationQuestionParameters).scaleTriangle == 'true',
            JSON.parse(evalSpec.evaluationQuestionParameters).q
          );
        }
        case 'EvaluTriangle': {
          return new EvaluTriangle(
            evalSpec.evaluationId,
            JSON.parse(evalSpec.evaluationQuestionParameters).img,
            JSON.parse(evalSpec.evaluationQuestionParameters).asw,
            evalSpec.evaluationMsResponseTimeGoal
          );
        }
        case 'EvaluDivision': {
          return new EvaluDivision(
            evalSpec.evaluationId,
            JSON.parse(evalSpec.evaluationQuestionParameters).v1,
            JSON.parse(evalSpec.evaluationQuestionParameters).v2,
            evalSpec.evaluationMsResponseTimeGoal
          );
        }
        case 'EvaluPuntos': {
          return new EvaluPuntos(
            evalSpec.evaluationId,
            evalSpec.evaluationMsResponseTimeGoal,
            JSON.parse(evalSpec.evaluationQuestionParameters).numPuntos
          );
        }
        case 'EvaluMusicNotes': {
          return new EvaluMusicNotes(
            evalSpec.evaluationId,
            evalSpec.evaluationMsResponseTimeGoal,
            JSON.parse(evalSpec.evaluationQuestionParameters).numPuntos
          );
        }
        case 'EvaluPlusFlash': {
          return new EvaluPlusFlash(
            evalSpec.evaluationId,
            JSON.parse(evalSpec.evaluationQuestionParameters).v1,
            JSON.parse(evalSpec.evaluationQuestionParameters).v2,
            evalSpec.evaluationMsResponseTimeGoal
          );
        }
        case 'EvaluMinusFlash': {
          return new EvaluMinusFlash(
            evalSpec.evaluationId,
            JSON.parse(evalSpec.evaluationQuestionParameters).v1,
            JSON.parse(evalSpec.evaluationQuestionParameters).v2,
            evalSpec.evaluationMsResponseTimeGoal
          );
        }
        default: {
          return null;
        }
      }
    });
    return evals;
  }

  get retos() {
    return this._retos;
  }

  getReto(retoId: string): RetoInterface {
    let r: RetoInterface;
    this.retosEnrolledObservable.subscribe(x => {
      console.log(x);
      r = x.find(c => c.id === retoId); // requiere las comillas para volverlo string
    });
    return r;
  }

  is_alive() {
    console.log('retos.service.ts: is_alive()');
  }

  // go_test() {
  //   // https://www.npmjs.com/package/typescript-json-serializer
  //   let qa = new QASuma(1, 3, 5);
  //   console.log("qa::: " + JSON.stringify(qa));

  //   // serialize
  //   const dataQA: any = serialize(qa);
  //   console.log("dataQA::: " + JSON.stringify(dataQA));

  //   // deserialize
  //   const qaNew: QAInterface = deserialize<QASuma>(dataQA, QASuma);
  //   console.log("qaNew::: " + JSON.stringify(qaNew));
  //   console.log("qaNew.getQ()::: " + qaNew.getQNew());
  //   console.log("qaNew.getQ()::: " + qaNew.getQNew());

  //   console.log("in retos service: " + new Date());
  //   console.log(this._retos[0]);
  //   let str = JSON.stringify(this._retos[0]);
  //   console.log(str);
  //   console.log("***************");
  //   let theReto: RetoInterface = this._retos[0];
  //   console.log(theReto.qas[0]);
  // }

  saveResponse(data, callbackFnct) {

    console.log('data to this.apiService.SendResponse: ' + JSON.stringify(data));

    this.apiService.SendResponse({
      usuarioId: data.usuarioId,
      evaluationId: data.evaluation_id,
      questionAsTxt: data.question,
      answerAsTxt: data.answer,
      responseAsTxt: data.response,
      score: data.score,
      msToRespond: data.ms_to_responde,
      didPass: data.did_pass,
      numStops: data.num_stops,
      createdAt: data.time_stamp_utc,
      keyStrokes:  data.key_strokes,
      agent: data.agent
    }).then(response => {

      console.log('response of SendResponse: ' + response);
      callbackFnct();
    });



    // var path = "/response";
    // console.log("saveResponse: M1");
    // let apiName = "cloud9-klugkidapi";

    // let myInit = {
    //   headers: {
    //     "x-api-key": "rxcQp7PiJRaeqwASIf74j3c8ZavmwldT6NU75Gsf",
    //     "Content-Type": "application/json"
    //   },
    //   body: {
    //     usuario: data.usuario,
    //     evaluation_id: data.evaluation_id,
    //     question: data.question,
    //     answer: data.answer,
    //     response: data.response,
    //     score: data.score,
    //     ms_to_responde: data.ms_to_responde,
    //     did_pass: data.did_pass,
    //     num_stops: data.num_stops,
    //     time_stamp_utc: data.time_stamp_utc,
    //     key_strokes: data.key_strokes,
    //     agent: data.agent
    //   }
    // };
    // console.log(apiName);
    // console.log(path);
    // console.log(myInit);
    // API.post(apiName, path, myInit)
    //   .then(response => {
    //     // Add your code here
    //     console.log("*** saveResponse: TODO OK ***");
    //     console.log(response);
    //     callbackFnct(response);
    //   })
    //   .catch(error => {
    //     console.log("*** saveResponse: FALLO ***");
    //     console.log(error);
    //     callbackFnct(error);
    //   });
  }


  usuarioHasRetoCreate(reto_id: string) {
    return this.apiService.CreateUsuarioHasReto({
      retoId: reto_id,
      usuarioId: this.authService.uiid,
      progress: 0.0
    });
  }


  usuarioHasRetoDelete(reto_id: string) {
    return this.apiService.DeleteUsuarioHasReto({
      retoId: reto_id,
      usuarioId: this.authService.uiid
    });
  }
}
