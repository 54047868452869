import { Injectable } from '@angular/core';
import { APIService, Area } from '../API.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AreasService {

  private areasObservableBS: BehaviorSubject<Area[]> =
    new BehaviorSubject<Area[]>([]);

  get areasObservable() {
    return this.areasObservableBS.asObservable();
  }

    // set areasObservableDate(areas: Area[] ) {
    //   this.areasObservableBS.next(areas);
    // }

  private areasMap = new Map<String, Object>();

  constructor(private apiService: APIService) {
    console.log('areas.service.ts: AreasService: CONSTRUCTOR');
    this.readAreas();
  }

  readAreas() {
    const la = this.apiService.ListAreas();
    console.log('la:');
    la.then((data) => {
      console.log('data : ' + data);
      console.log('data.items: ' + data.items);

      for (let v of data.items) {
        this.areasMap.set(v.areaId, v);
      }


      this.areasObservableBS.next(data.items);

    });
  }

  getImageByAreaId(areaId: String): any {
    return this.areasMap.get(areaId);
  }
}
