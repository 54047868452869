import { EvaluInterface } from "./evalu-interface.model";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export class EvaluImage implements EvaluInterface {
  private id: string;
  private img: string;
  private asw: string;
  private rt: number;
  
  private sanitizer: DomSanitizer;
  svg:SafeHtml;

  constructor(_id: string, _img: string, _asw: string, _rt: number) {
    this.id = _id;
    this.img = _img;
    this.asw = _asw ;
    this.rt = _rt ;
  }

  getId(): string {
    return this.id;
  }

  getQNew() {
    return this.getQ();
  }

  getQ(): string {
    return this.img;
  }
  
  getQShort(): string {
    return 'Img:'+this.asw;
  }

  getA(): string {
    return this.asw;
  }

  gradeAnswer(answer: string): number {
    console.log(answer);
    console.log(this.asw.length);
    var g = 1 - this.levenshteinDistance(this.asw, answer) / this.asw.length;
    return g < 0 ? 0 : g;
  }

  getTimeToRespondMs() {
    return this.rt;
  }

  getEvalDisplay() {
    return "imageDisplay";
  }
  
  levenshteinDistance(a: string, b: string) {
    if (a.length == 0) return b.length;
    if (b.length == 0) return a.length;

    var matrix = [];

    // increment along the first column of each row
    var i: number;
    for (i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    // increment each column in the first row
    var j: number;
    for (j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (i = 1; i <= b.length; i++) {
      for (j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) == a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            Math.min(
              matrix[i][j - 1] + 1, // insertion
              matrix[i - 1][j] + 1
            )
          ); // deletion
        }
      }
    }

    return matrix[b.length][a.length];
  }  
}
